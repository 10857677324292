import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { SxStyles } from "../../theme";
import { TableFiltersProps } from "./TableFilters.types";

export function TableFilters({ children, onReset }: TableFiltersProps) {
  return (
    <Paper sx={sx.filterContainer}>
      <Typography sx={sx.filtersLabel}>Filter</Typography>
      <Box sx={sx.filters}>
        {onReset && (
          <Button
            sx={sx.resetButton}
            onClick={onReset}
          >
            Reset
          </Button>
        )}
        {children}
      </Box>
    </Paper>
  );
}

const sx: SxStyles = {
  filterContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    px: "15px",
    backgroundColor: "secondary.light",
  },
  filtersLabel: { fontWeight: 700, position: "absolute", top: 5, left: 15 },
  filters: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: "center",
    gap: { xs: "15px", sm: "30px" },
    minHeight: "60px",
    pt: "30px",
    pb: "15px",
    width: "100%",
  },
  resetButton: { height: "25px", width: { xs: "100px", sm: "80px" }, mt: "20px" },
};
