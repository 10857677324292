import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { SxStyles } from "../../theme";
import { routes } from "../../utilities/routes";
import { ExpandMenuButtonDesktop } from "./ExpandMenuButtons/ExpandMenuButtonDesktop";
import { NavLinkProps } from "./MenuWrapper.types";

export const CreateNavLink = ({ page }: NavLinkProps) => {
  switch (page.path) {
    case routes.edu().init:
      return (
        <ExpandMenuButtonDesktop
          page={page}
          pageName={routes.edu().init}
        />
      );

    case routes.content().init:
      return (
        <ExpandMenuButtonDesktop
          page={page}
          pageName={routes.content().init}
        />
      );

    default:
      return (
        <Button
          variant="text"
          component={NavLink}
          sx={sx.navLink}
          to={page.path}
        >
          {page.label}
        </Button>
      );
  }
};

const sx: SxStyles = {
  navLink: {
    borderRadius: 0,
    color: "common.white",
    "&.active": {
      borderBottom: "2px solid white",
    },
  },
};
