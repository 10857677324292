import { Language } from "../graphql/client";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import { SxStyles } from "../theme";
import { LanguageSelectProps } from "../hooks/types";

export const languageOptions = [Language.English, Language.Icelandic];

export const LanguageSelect = ({ control }: LanguageSelectProps) => (
  <Box sx={sx.languageSelect}>
    <Controller
      control={control}
      name="language"
      render={({ field: { onChange, value } }) => (
        <FormControl
          variant="standard"
          fullWidth
        >
          <InputLabel>Language</InputLabel>
          <Select
            label="Language"
            defaultValue={Language.English}
            value={value}
            onChange={onChange}
          >
            {languageOptions.map((language) => (
              <MenuItem
                key={language}
                value={language}
              >
                {language.charAt(0) + language.slice(1).toLowerCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  </Box>
);
export const sx: SxStyles = {
  languageSelect: { maxWidth: "200px", width: "100%" },
};
