import {
  Box,
  FormControlLabel,
  FormLabel,
  Paper,
  TextField,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DataGrid, GridEventListener, GridSortModel } from "@mui/x-data-grid";
import { FiltersForm, Sort } from "./PageUsers.types";
import { useCallback, useEffect, useState } from "react";
import { SxStyles } from "../../theme";
import { Controller, useForm } from "react-hook-form";
import debounce from "lodash.debounce";
import { TableFilters } from "../../components/TableFilters/TableFilters";
import { Header } from "../../components/Header/Header";
import { Container } from "../../components/Container/Container";
import { checkEmailStatusFilter, formatUsers, useColumns } from "./helpers";
import { IdentitySortField, SortDirection, useUsersQuery } from "../../graphql/client";
import { useServerPagination } from "../../hooks/useDataGrid";
import { useHandleError } from "../../hooks/useHandleError";
import { TABLE_HEIGHT } from "../../helpers/sx";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";

export function PageUsers() {
  const { register, reset, control, getValues, watch } = useForm<FiltersForm>({
    defaultValues: {
      searchTerm: "",
      emailConfirmed: "all",
    },
  });
  const handleError = useHandleError();
  const watchEmailFilter = watch("emailConfirmed");
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const [sort, setSort] = useState<Sort>({
    identitySortField: IdentitySortField.RegisteredAt,
    sortDirectionDesc: true,
  });

  const [pageCursor, setPageCursor] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState(8);

  const { data, refetch, isFetching } = useUsersQuery(
    {
      sortDefinition: {
        identitySortField: sort.identitySortField as IdentitySortField,
        sortDirection: sort.sortDirectionDesc ? SortDirection.Desc : SortDirection.Asc,
      },
      meta: {
        pageSize: pageSize,
        pageCursor: pageCursor,
      },
      filters: {
        emailConfirmed: checkEmailStatusFilter(getValues("emailConfirmed")),
        searchTerm: getValues("searchTerm"),
      },
    },

    { select: formatUsers, keepPreviousData: true, onError: (e: Error) => handleError(e) }
  );

  const { handlePageChange, currentCursor, resetPages, page } = useServerPagination(isFetching);

  useEffect(() => {
    setPageCursor(currentCursor);
  }, [currentCursor]);

  const columns = useColumns(refetch);

  useUpdateEffect(() => {
    refetch();
  }, [watchEmailFilter, refetch]);

  useUpdateEffect(() => {
    refetch();
    setTriggerRefetch(false);
  }, [triggerRefetch, refetch]);

  const debouncedChangeHandler = debounce(() => setTriggerRefetch(true), 400);

  const handleChangeSortField = useCallback(
    (name: IdentitySortField) => {
      if (name === sort.identitySortField) {
        setSort((prev) => ({ ...prev, sortDirectionDesc: !prev.sortDirectionDesc }));
      } else {
        setSort({ ...sort, identitySortField: name });
      }
      resetPages();
    },
    [resetPages, sort]
  );

  const handleHeaderClick: GridEventListener<"columnHeaderClick"> = (event) => {
    const name = event.colDef.field.toUpperCase();

    if (Object.values(IdentitySortField).includes(name as IdentitySortField)) {
      handleChangeSortField(name as IdentitySortField);
    }
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    resetPages();
  };

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      if (sortModel.length > 0) {
        const fieldName = sortModel[0].field.toUpperCase() as IdentitySortField;
        const isSortDesc = sortModel[0].sort === "desc";
        setSort({ identitySortField: fieldName, sortDirectionDesc: isSortDesc });
      } else {
        const fieldName = IdentitySortField.RegisteredAt;
        const isSortDesc = true;
        setSort({ identitySortField: fieldName, sortDirectionDesc: isSortDesc });
      }
      resetPages();
    },
    [resetPages]
  );

  const handleResetFilter = useCallback(() => {
    reset();
    resetPages();
    setSort({ identitySortField: IdentitySortField.RegisteredAt, sortDirectionDesc: true });
  }, [reset, resetPages]);

  return (
    <Container>
      <Header>User Management</Header>
      <Paper sx={sx.table}>
        <TableFilters onReset={handleResetFilter}>
          <Box sx={sx.filters}>
            <TextField
              label="Search"
              sx={sx.input}
              placeholder="Jerry Smith"
              {...register("searchTerm", {
                onChange: debouncedChangeHandler,
              })}
            />
            <Box sx={sx.radioContainer}>
              <FormLabel sx={sx.radioButtonsLabel}>Email status</FormLabel>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <RadioGroup sx={sx.radioButtons}>
                    <FormControlLabel
                      checked={value === "all"}
                      value="all"
                      onChange={onChange}
                      control={
                        <Radio
                          size="small"
                          sx={sx.radio}
                        />
                      }
                      label="All"
                    />
                    <FormControlLabel
                      value="confirmed"
                      checked={value === "confirmed"}
                      onChange={onChange}
                      control={<Radio size="small" />}
                      label="Confirmed"
                    />
                    <FormControlLabel
                      value="unconfirmed"
                      checked={value === "unconfirmed"}
                      onChange={onChange}
                      sx={sx.lastRadio}
                      control={<Radio size="small" />}
                      label="Unconfirmed"
                    />
                  </RadioGroup>
                )}
                name="emailConfirmed"
                control={control}
              />
            </Box>
          </Box>
        </TableFilters>
        <DataGrid
          onColumnHeaderClick={handleHeaderClick}
          columns={columns}
          rows={data?.rows || []}
          rowCount={data?.meta.totalCount || 0}
          paginationMode="server"
          pageSize={pageSize}
          rowsPerPageOptions={[8, 16, 32]}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={(newPage) => {
            handlePageChange(newPage, data?.meta.nextPageCursor || "");
          }}
          page={page}
          disableSelectionOnClick
          disableColumnMenu
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          loading={isFetching}
          disableVirtualization
        />
      </Paper>
    </Container>
  );
}

const sx: SxStyles = {
  table: {
    display: "flex",
    flexDirection: "column",
    height: `${TABLE_HEIGHT}px`,
    maxHeight: "100%",
    padding: "25px",
    overflow: "hidden",
    gap: "20px",
  },
  input: { minWidth: "30px", height: "45px", flexDirection: "row" },
  radioContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "right",
  },
  radioButtonsLabel: { fontWeight: "500", fontSize: "0.875rem" },
  radioButtons: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    flexWrap: "nowrap",
    gap: { lg: "10px" },
  },
  lastRadio: { mr: 0 },
  filters: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
