import { useForm } from "react-hook-form";
import { Treatment, TreatmentDialogProps } from "./PageTreatments.types";
import { useHandleError } from "../../hooks/useHandleError";
import { useEffect } from "react";
import { useAlertContext } from "../../contexts/AlertContext/AlertContext";
import { Box, Button, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import { Dialog } from "../../components/Dialog/Dialog";
import { sx as style } from "../../helpers/sx";
import { Form } from "../../components/Form/Form";
import { LoadingButton } from "@mui/lab";
import { useCreateTreatmentMutation, useUpdateTreatmentMutation } from "../../graphql/client";
import { ValidationMessage } from "../../utilities/enums";
import { SxStyles } from "../../theme";

export function TreatmentDialog({
  isOpen,
  handleClose,
  editData,
  rowsRefetch,
}: TreatmentDialogProps) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Treatment>();

  const handleError = useHandleError();

  const { mutate: createTreatmentMutate, isLoading: isLoadingCreate } = useCreateTreatmentMutation({
    onError: (e: Error) => handleCreateTreatmentError(e),
    onSuccess: () => handleCreateTreatmentSuccess(),
  });

  const { mutate: updateTreatmentMutate, isLoading: isLoadingUpdate } = useUpdateTreatmentMutation({
    onError: (e: Error) => handleUpdateTreatmentError(e),
    onSuccess: () => handleUpdateTreatmentSuccess(),
  });

  const handleCreateTreatmentSuccess = () => {
    reset();
    handleClose();
    rowsRefetch();
    openSnack({ type: "success", info: "Treatment created" });
  };

  const handleCreateTreatmentError = (e: Error) => {
    handleError(e, "Failed to create treatment.");
  };

  const handleUpdateTreatmentSuccess = () => {
    reset();
    handleClose();
    rowsRefetch();
    openSnack({ type: "success", info: "Treatment updated" });
  };

  const handleUpdateTreatmentError = (e: Error) => {
    handleError(e, "Failed to update treatment.");
  };

  useEffect(() => {
    if (editData) {
      setValue("englishTitle", editData.englishTitle);
      setValue("englishDescription", editData.englishDescription);
      setValue("icelandicTitle", editData.icelandicTitle);
      setValue("icelandicDescription", editData.icelandicDescription);
      setValue("priority", editData.priority);
    } else {
      reset();
    }
  }, [editData, reset, setValue]);

  const { openSnack } = useAlertContext();

  const onSubmit = handleSubmit(async (data) => {
    if (editData) {
      updateTreatmentMutate({
        input: {
          id: editData.id,
          englishDescription: getValues("englishDescription"),
          englishTitle: getValues("englishTitle"),
          icelandicDescription: getValues("icelandicDescription"),
          icelandicTitle: getValues("icelandicTitle"),
          priority: Number(getValues("priority")),
        },
      });
    } else {
      createTreatmentMutate({
        input: {
          englishDescription: getValues("englishDescription"),
          englishTitle: getValues("englishTitle"),
          icelandicDescription: getValues("icelandicDescription"),
          icelandicTitle: getValues("icelandicTitle"),
          priority: Number(getValues("priority")),
        },
      });
    }
  });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      title={editData ? "Treatment update" : "Create new treatment"}
    >
      <DialogContent sx={sx.dialogContent}>
        <Form onSubmit={onSubmit}>
          <Box sx={sx.formContainer}>
            <Box>
              <Typography sx={sx.dialogLabel}>English</Typography>
              <Box sx={sx.inputs}>
                <TextField
                  fullWidth
                  label="Title"
                  {...register("englishTitle", {
                    required: true,
                  })}
                  error={!!errors.englishTitle}
                  helperText={!!errors.englishTitle && ValidationMessage.Required}
                />
                <TextField
                  label="Description"
                  variant="outlined"
                  placeholder="Description"
                  multiline
                  rows={7}
                  {...register("englishDescription", {
                    required: true,
                  })}
                  error={!!errors.englishDescription}
                  helperText={!!errors.englishDescription && ValidationMessage.Required}
                />
              </Box>
            </Box>
            <Box>
              <Typography sx={sx.dialogLabel}>Icelandic</Typography>
              <Box sx={sx.inputs}>
                <TextField
                  fullWidth
                  label="Title"
                  {...register("icelandicTitle", {
                    required: true,
                  })}
                  error={!!errors.icelandicTitle}
                  helperText={!!errors.icelandicTitle && ValidationMessage.Required}
                />
                <TextField
                  label="Description"
                  variant="outlined"
                  placeholder="Description"
                  multiline
                  rows={7}
                  {...register("icelandicDescription", {
                    required: true,
                  })}
                  error={!!errors.icelandicDescription}
                  helperText={!!errors.icelandicDescription && ValidationMessage.Required}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={sx.bottomPanel}>
            <TextField
              sx={style.priorityInput}
              label="Priority"
              variant="outlined"
              type="number"
              placeholder="0"
              {...register("priority", {
                min: 0,
              })}
              error={!!errors.priority}
              helperText={!!errors.priority && ValidationMessage.NonNegativeNumber}
            />
            <DialogActions sx={sx.buttons}>
              <Button
                onClick={handleClose}
                variant="text"
                sx={sx.cancelButton}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isLoadingCreate || isLoadingUpdate}
                disabled={isLoadingCreate || isLoadingUpdate}
                type="submit"
                variant="contained"
              >
                {editData ? "Update" : "Create"}
              </LoadingButton>
            </DialogActions>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

const sx: SxStyles = {
  dialogContent: {
    overflowX: "hidden",
    maxWidth: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: "30px",
    p: "15px",
  },
  inputs: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "400px",
    maxWidth: "100%",
  },
  dialogLabel: { fontWeight: 600 },
  buttons: { m: "20px 0 0 auto", p: 0 },
  submitButton: {
    backgroundColor: "navyBlueDark.main",
    color: "common.white",
    "&:hover": { backgroundColor: "darkBlue.main" },
  },
  cancelButton: {
    color: "common.black",
    "&:hover": { textDecoration: "underline" },
    fontWeight: 600,
  },
  errorMsg: { color: "error.main", fontSize: "0.75rem" },
  bottomPanel: {
    p: "0 15px 15px 15px",
  },
};
