import { Box } from "@mui/material";
import { SxStyles } from "../../theme";
import { ContainerProps } from "./Container.types";

export function Container({ children }: ContainerProps) {
  return <Box sx={sx.container}>{children}</Box>;
}

const sx: SxStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    minHeight: "500px",
    width: "100%",
    height: "100%",
    gap: "30px",
  },
};
