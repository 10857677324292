import { useAlertContext } from "../contexts/AlertContext/AlertContext";
import { useAuthContext } from "../contexts/AuthContext/AuthContext";
import { ErrorCode } from "./types";

export const useHandleError = () => {
  const { handleUnauthorized } = useAuthContext();
  const { openSnack } = useAlertContext();

  const handleError = (error: Error, msg: string = "") => {
    if (error.cause === ErrorCode.Authentication) {
      handleUnauthorized();
      openSnack({ type: "error", info: `${msg} ${error.message}` });
    } else {
      openSnack({ type: "error", info: `${msg} ${error.message}` });
    }
  };

  return handleError;
};
