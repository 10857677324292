import { GridColDef } from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import { MenuActions } from "../../../components/MenuActions/MenuActions";
import { MenuOption } from "../../../components/MenuActions/MenuActions.types";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialog/ConfirmationDialog";
import { TextContentButton } from "../../../components/TextContentButton/TextContentButton";
import { OverflowTooltip } from "../../../components/OverflowTooltip/OverflowTooltip";
import {
  FrequentlyAskedQuestionsQuery,
  Language,
  useDeleteFrequentlyAskedQuestionMutation,
} from "../../../graphql/client";
import { ContentFormData } from "../../../components/ContentFormDialog/ContentFormDialog.types";
import {
  FreqAskedQuestionDataModel,
  UseFreqAskedQuestionReturn,
} from "./PageFreqAskedQuestions.types";
import { useHandleMutationResponse } from "../../../hooks/useHandleMutationResponse";
import { RefetchType } from "../../../hooks/types";
import { columnPriority } from "../../../helpers/columnDef";

export const useColumns = (
  handleStartEdit: (obj: ContentFormData) => void,
  language: Language,
  refetch: RefetchType
) => {
  const confirm = useConfirmationDialog();
  const { handleMutationSuccess, handleMutationError } = useHandleMutationResponse();

  const { mutate: deleteMutate } = useDeleteFrequentlyAskedQuestionMutation({
    onError: (e: Error) => handleMutationError(e),
    onSuccess: () => handleMutationSuccess(refetch, "Question deleted"),
  });

  const createOptions = (obj: ContentFormData) => {
    const optionsList: MenuOption[] = [
      {
        optionText: "Edit",
        operation: () => handleStartEdit(obj),
      },
      {
        optionText: "Delete",
        operation: () =>
          confirm({
            title: "Delete question",
            text: "Are you sure?\n(all translations will be deleted)",
          }).then(() => {
            deleteMutate({ frequentlyAskedQuestionId: obj.id });
          }),
      },
    ];

    return optionsList;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return <OverflowTooltip title={params.row.id}>{params.row.id}</OverflowTooltip>;
      },
    },
    {
      field: "question",
      headerName: "Question",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <TextContentButton content={params.row[`${language.toLowerCase()}Question`]} />;
      },
    },
    {
      field: "answer",
      headerName: "Answer",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <TextContentButton content={params.row[`${language.toLowerCase()}Answer`]} />;
      },
    },
    columnPriority,
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const formObj: ContentFormData = {
          id: params.row.id,
          englishFieldFirst: params.row.englishQuestion,
          englishFieldSecond: params.row.englishAnswer,
          icelandicFieldFirst: params.row.icelandicQuestion,
          icelandicFieldSecond: params.row.icelandicAnswer,
          priority: params.row.priority,
        };
        return (
          <MenuActions
            menuIcon={SettingsIcon}
            options={createOptions(formObj)}
            id={params.row.id}
          />
        );
      },
    },
  ];

  return columns;
};

export const formatFrequentlyAskedQuestions = (
  data: FrequentlyAskedQuestionsQuery
): UseFreqAskedQuestionReturn => {
  const questions = data.content.listFrequentlyAskedQuestionsWithDetails.items;
  let questionObj: FreqAskedQuestionDataModel;
  let rowsArray: FreqAskedQuestionDataModel[] = questions?.map((question) => {
    if (question.details[0]?.language === Language.English) {
      questionObj = {
        id: question.id.toString(),
        englishQuestion: question.details[0].question,
        englishAnswer: question.details[0].answer,
        icelandicQuestion: question.details[1]?.question || "",
        icelandicAnswer: question.details[1]?.answer || "",
        priority: question.priority,
      };
    } else {
      questionObj = {
        id: question.id.toString(),
        englishQuestion: question.details[1]?.question || "",
        englishAnswer: question.details[1]?.answer || "",
        icelandicQuestion: question.details[0]?.question || "",
        icelandicAnswer: question.details[0]?.answer || "",
        priority: question.priority,
      };
    }

    return questionObj;
  });

  return { rows: rowsArray, meta: data.content.listFrequentlyAskedQuestionsWithDetails.meta };
};
