import { GridColDef } from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import { MenuActions } from "../../../components/MenuActions/MenuActions";
import { MenuOption } from "../../../components/MenuActions/MenuActions.types";
import { useConfirmationDialog } from "../../../contexts/ConfirmationDialog/ConfirmationDialog";
import { TextContentButton } from "../../../components/TextContentButton/TextContentButton";
import { OverflowTooltip } from "../../../components/OverflowTooltip/OverflowTooltip";
import {
  GeneralRecommendationsQuery,
  Language,
  useDeleteGeneralRecommendationMutation,
} from "../../../graphql/client";
import { ContentFormData } from "../../../components/ContentFormDialog/ContentFormDialog.types";
import {
  GeneralRecommendationDataModel,
  UseGeneralRecommendationsReturn,
} from "./PageGeneralRecommendations.types";
import { useHandleMutationResponse } from "../../../hooks/useHandleMutationResponse";
import { RefetchType } from "../../../hooks/types";
import { columnPriority } from "../../../helpers/columnDef";

export const useColumns = (
  handleStartEdit: (obj: ContentFormData) => void,
  language: Language,
  refetch: RefetchType
) => {
  const confirm = useConfirmationDialog();
  const { handleMutationSuccess, handleMutationError } = useHandleMutationResponse();

  const { mutate: deleteMutate } = useDeleteGeneralRecommendationMutation({
    onError: (e: Error) => handleMutationError(e),
    onSuccess: () => handleMutationSuccess(refetch, "Recommendation deleted"),
  });

  const createOptions = (obj: ContentFormData) => {
    const optionsList: MenuOption[] = [
      {
        optionText: "Edit",
        operation: () => handleStartEdit(obj),
      },
      {
        optionText: "Delete",
        operation: () =>
          confirm({
            title: "Delete recommendation",
            text: "Are you sure?\n(all translations will be deleted)",
          }).then(() => {
            deleteMutate({ input: { id: obj.id } });
          }),
      },
    ];

    return optionsList;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return <OverflowTooltip title={params.row.id}>{params.row.id}</OverflowTooltip>;
      },
    },
    {
      field: "advice",
      headerName: "Advice",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <TextContentButton content={params.row[`${language.toLowerCase()}Advice`]} />;
      },
    },
    {
      field: "hint",
      headerName: "Hint",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <TextContentButton content={params.row[`${language.toLowerCase()}Hint`]} />;
      },
    },
    columnPriority,
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const formObj: ContentFormData = {
          id: params.row.id,
          englishFieldFirst: params.row.englishAdvice,
          englishFieldSecond: params.row.englishHint,
          icelandicFieldFirst: params.row.icelandicAdvice,
          icelandicFieldSecond: params.row.icelandicHint,
          priority: params.row.priority,
        };
        return (
          <MenuActions
            menuIcon={SettingsIcon}
            options={createOptions(formObj)}
            id={params.row.id}
          />
        );
      },
    },
  ];

  return columns;
};

export const formatGeneralRecommendations = (
  data: GeneralRecommendationsQuery
): UseGeneralRecommendationsReturn => {
  const recommendations = data.content.listGeneralRecommendationsWithDetails.items;
  let recommendationObj: GeneralRecommendationDataModel;
  let rowsArray: GeneralRecommendationDataModel[] = recommendations?.map((recommendation) => {
    if (recommendation.details[0]?.language === Language.English) {
      recommendationObj = {
        id: recommendation.id.toString(),
        englishAdvice: recommendation.details[0].advice,
        englishHint: recommendation.details[0].hint,
        icelandicAdvice: recommendation.details[1]?.advice || "",
        icelandicHint: recommendation.details[1]?.hint || "",
        priority: recommendation.priority,
      };
    } else {
      recommendationObj = {
        id: recommendation.id.toString(),
        englishAdvice: recommendation.details[1]?.advice || "",
        englishHint: recommendation.details[1]?.hint || "",
        icelandicAdvice: recommendation.details[0]?.advice || "",
        icelandicHint: recommendation.details[0]?.hint || "",
        priority: recommendation.priority,
      };
    }

    return recommendationObj;
  });

  return { rows: rowsArray, meta: data.content.listGeneralRecommendationsWithDetails.meta };
};
