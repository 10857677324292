/* eslint-disable no-unused-vars */
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";
import { Control } from "react-hook-form";
import { Language } from "../graphql/client";

export type LanguageOptions = { language: Language };
export type LanguageSelectProps = { control: Control<LanguageOptions> };

export enum ErrorCode {
  Authentication = "AUTHENTICATION",
  Validation = "VALIDATION",
}

export type RefetchType = <TPageData>(
  options?: RefetchOptions & RefetchQueryFilters<TPageData>
) => Promise<QueryObserverResult>;
