import { useAuthContext } from "../contexts/AuthContext/AuthContext";
import { useGetMyDetailsQuery } from "../graphql/client";
import { useHandleError } from "./useHandleError";

export const useAuthGuard = () => {
  const { isLoggedIn, setUserAccount } = useAuthContext();
  const handleError = useHandleError();

  const { data } = useGetMyDetailsQuery({}, { onError: handleError });

  const authCheck = () => {
    if (!isLoggedIn) {
      if (data) {
        const details = data?.identity.getMyDetails;
        const { id, name = "", email = "" } = details;
        setUserAccount({ id, name, email });
      }
    }
  };

  return authCheck;
};
