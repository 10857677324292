import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { sx as sxStyles } from "../../helpers/sx";
import { SxStyles } from "../../theme";
import { ValidationMessage } from "../../utilities/enums";
import { Dialog } from "../Dialog/Dialog";
import { Form } from "../Form/Form";
import { ContentFormData, ContentFormDialogProps, FetchResult } from "./ContentFormDialog.types";

export const ContentFormDialog = ({
  isOpen,
  handleClose,
  editData,
  firstLabel,
  secondLabel = "",
  contentName,
  handleFetch,
  fetchResult,
  clearFetchResult,
  isLoading,
}: ContentFormDialogProps) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ContentFormData>();

  const onSubmit = handleSubmit((data, e) => {
    handleFetch(data);
  });

  const handleCloseDialog = useCallback(() => {
    handleClose();
    reset();
  }, [handleClose, reset]);

  useEffect(() => {
    if (editData) {
      const {
        englishFieldFirst,
        icelandicFieldFirst,
        englishFieldSecond,
        icelandicFieldSecond,
        priority,
      } = editData as ContentFormData;
      setValue("englishFieldFirst", englishFieldFirst);
      setValue("englishFieldSecond", englishFieldSecond || "example english FieldSecond");
      setValue("icelandicFieldFirst", icelandicFieldFirst);
      setValue("icelandicFieldSecond", icelandicFieldSecond || "example icelandic FieldSecond");
      setValue("priority", priority);
    }
  }, [editData, setValue]);

  useEffect(() => {
    if (fetchResult === FetchResult.success && clearFetchResult) {
      handleCloseDialog();
      clearFetchResult();
    }
  }, [clearFetchResult, fetchResult, handleCloseDialog]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      title={editData ? `${contentName} update` : `Create new ${contentName}`}
      size="lg"
    >
      <DialogContent sx={sx.dialogContent}>
        <Form onSubmit={onSubmit}>
          <Box sx={sx.formContainer}>
            <Box sx={sx.inputsContainer}>
              <Box sx={sx.inputContainer}>
                <Typography sx={sx.dialogLabel}>English</Typography>
                <TextField
                  label={firstLabel}
                  variant="outlined"
                  placeholder={firstLabel}
                  multiline
                  rows={7}
                  {...register("englishFieldFirst", {
                    required: true,
                  })}
                  error={!!errors.englishFieldFirst}
                  helperText={!!errors.englishFieldFirst && ValidationMessage.Required}
                />
                {secondLabel && (
                  <TextField
                    label={secondLabel}
                    variant="outlined"
                    placeholder={secondLabel}
                    multiline
                    rows={7}
                    {...register("englishFieldSecond", {
                      required: true,
                    })}
                    error={!!errors.englishFieldSecond}
                    helperText={!!errors.englishFieldSecond && ValidationMessage.Required}
                  />
                )}
              </Box>
              <Box sx={sx.inputContainer}>
                <Typography sx={sx.dialogLabel}>Icelandic</Typography>
                <TextField
                  label={firstLabel}
                  variant="outlined"
                  placeholder={firstLabel}
                  multiline
                  rows={7}
                  {...register("icelandicFieldFirst", {
                    required: true,
                  })}
                  error={!!errors.icelandicFieldFirst}
                  helperText={!!errors.icelandicFieldFirst && ValidationMessage.Required}
                />
                {secondLabel && (
                  <TextField
                    label={secondLabel}
                    variant="outlined"
                    placeholder={secondLabel}
                    multiline
                    rows={7}
                    {...register("icelandicFieldSecond", {
                      required: true,
                    })}
                    error={!!errors.icelandicFieldSecond}
                    helperText={!!errors.icelandicFieldSecond && ValidationMessage.Required}
                  />
                )}
              </Box>
            </Box>
            <Box sx={sx.inputContainer}>
              <TextField
                sx={sx.priorityInput}
                label="Priority"
                variant="outlined"
                type="number"
                placeholder="0"
                {...register("priority", {
                  min: 0,
                })}
                error={!!errors.priority}
                helperText={!!errors.priority && ValidationMessage.NonNegativeNumber}
              />
            </Box>
            <DialogActions sx={sx.buttons}>
              <Button
                onClick={handleCloseDialog}
                variant="text"
                sx={sxStyles.cancelButton}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isLoading}
                disabled={isLoading}
                type="submit"
                variant="contained"
                sx={sxStyles.submitButton}
              >
                {editData ? "Update" : "Create"}
              </LoadingButton>
            </DialogActions>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const sx: SxStyles = {
  dialogContent: {
    width: "100%",
    p: 0,
  },
  formContainer: { display: "flex", flexDirection: "column", pt: "20px", p: "30px" },
  inputsContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: "40px",
  },
  dialogLabel: { fontWeight: 600 },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    width: "100%",
    mx: "auto",
  },
  buttons: { m: "20px 0 0 auto", p: 0 },
  priorityInput: { width: "150px", marginTop: "25px" },
};
