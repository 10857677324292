import { GridColDef } from "@mui/x-data-grid";
import { MenuActions } from "../../components/MenuActions/MenuActions";
import { useAlertContext } from "../../contexts/AlertContext/AlertContext";
import { useConfirmationDialog } from "../../contexts/ConfirmationDialog/ConfirmationDialog";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  UsersQuery,
  useUserBanMutation,
  useUserDeleteMutation,
  useUserUnbanMutation,
} from "../../graphql/client";
import { Typography } from "@mui/material";
import { SxStyles } from "../../theme";
import { DateTime } from "luxon";
import { OverflowTooltip } from "../../components/OverflowTooltip/OverflowTooltip";
import { UseUsersReturn } from "./PageUsers.types";

export const useColumns = (refetch: () => void) => {
  const confirm = useConfirmationDialog();
  const { openSnack } = useAlertContext();

  const { mutate: deleteMutate } = useUserDeleteMutation({
    onError: (e: Error) => handleDeleteError(e),
    onSuccess: () => handleDeleteSuccess(),
  });

  const { mutate: banUserMutate } = useUserBanMutation({
    onError: (e: Error) => handleBanError(e),
    onSuccess: () => handleBanSuccess(),
  });

  const { mutate: unbanUserMutate } = useUserUnbanMutation({
    onError: (e: Error) => handleUnbanError(e),
    onSuccess: () => handleUnbanSuccess(),
  });

  const handleDeleteSuccess = () => {
    openSnack({ type: "success", info: "User deleted" });
    refetch();
  };

  const handleDeleteError = (e: Error) => {
    openSnack({ type: "error", info: "Failed to delete user" });
  };

  const handleBanSuccess = () => {
    openSnack({ type: "success", info: "User banned" });
  };

  const handleBanError = (e: Error) => {
    refetch();
    openSnack({ type: "error", info: "Failed to ban user" });
  };

  const handleUnbanSuccess = () => {
    openSnack({ type: "success", info: "User unbanned" });
  };

  const handleUnbanError = (e: Error) => {
    refetch();
    openSnack({ type: "error", info: "Failed to unban user" });
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => {
        return <OverflowTooltip title={params.row.id}>{params.row.id}</OverflowTooltip>;
      },
    },
    {
      field: "name",
      headerName: "User name",
      width: 200,
      filterable: false,
      renderCell: (params) => {
        return <OverflowTooltip title={params.row.name}>{params.row.name}</OverflowTooltip>;
      },
    },
    {
      field: "email",
      headerName: "User email",
      flex: 1,
      minWidth: 200,
      filterable: false,
      renderCell: (params) => {
        return <OverflowTooltip title={params.row.email}>{params.row.email}</OverflowTooltip>;
      },
    },
    {
      field: "isEmailConfirmed",
      headerName: "Email status",
      flex: 1,
      minWidth: 150,
      filterable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.isEmailConfirmed ? "Confirmed" : "Unconfirmed";
      },
    },
    {
      field: "registered_at",
      headerName: "Registered at",
      flex: 1,
      minWidth: 200,
      filterable: false,
      renderCell: (params) => {
        return <>{DateTime.fromISO(params.row.registeredAt).toFormat("dd/MM/yyyy - HH:mm:ss")}</>;
      },
    },
    {
      field: "isBanned",
      headerName: "Account status",
      flex: 1,
      minWidth: 150,
      filterable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row.isBanned ? (
              <Typography sx={sx.banned}>Banned</Typography>
            ) : (
              <Typography sx={sx.active}>Active</Typography>
            )}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 100,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const isBanned = params.row.isBanned;
        return (
          <MenuActions
            menuIcon={ManageAccountsIcon}
            options={[
              {
                optionText: isBanned ? "Unban" : "Ban",
                operation: (id: string) =>
                  confirm({
                    title: `${isBanned ? "Unban" : "Ban"} user`,
                    text: "Are you sure?",
                  }).then(() => {
                    if (isBanned) {
                      unbanUserMutate({ identityId: id });
                      params.row.isBanned = false;
                    } else {
                      banUserMutate({ identityId: id });
                      params.row.isBanned = true;
                    }
                  }),
              },
              {
                optionText: "Delete",
                operation: (id: string) =>
                  confirm({
                    title: "Delete user",
                    text: "Are you sure?",
                  }).then(() => {
                    deleteMutate({ identityId: id });
                  }),
              },
            ]}
            id={params.row.id}
          />
        );
      },
    },
  ];

  return columns;
};

export const checkEmailStatusFilter = (status: string) => {
  switch (status) {
    case "all":
      return null;
    case "confirmed":
      return true;
    case "unconfirmed":
      return false;
  }
};

const sx: SxStyles = {
  banned: { color: "error.main", fontSize: "0.875rem" },
  active: { fontSize: "0.875rem" },
};

export const formatUsers = (data: UsersQuery): UseUsersReturn => {
  return { rows: data.identity.list.items, meta: data.identity.list.meta };
};
