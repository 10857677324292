import { useLayoutEffect, useState, useRef } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { FileCopy } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAlertContext } from "../../contexts/AlertContext/AlertContext";
import { OverflowTooltipProps } from "./OverflowTooltip.types";
import { SxStyles } from "../../theme";

export const OverflowTooltip = (props: OverflowTooltipProps) => {
  const { title, children, noCopy } = props;
  const inputRef = useRef<HTMLDivElement>(null);
  const [hoverStatus, setHover] = useState(false);
  const { openSnack } = useAlertContext();

  useLayoutEffect(() => {
    if (inputRef && inputRef.current) {
      const compare = inputRef.current.scrollWidth > inputRef.current.clientWidth;
      setHover(compare);
    }
  }, [inputRef?.current?.offsetWidth]);

  const tooltipContent = noCopy ? (
    title
  ) : (
    <Box sx={sx.tooltip}>
      {title}
      <CopyToClipboard text={title}>
        <IconButton
          color="primary"
          sx={sx.copyButton}
          onClick={() => openSnack({ type: "info", info: "Text copied" })}
        >
          <FileCopy />
        </IconButton>
      </CopyToClipboard>
    </Box>
  );

  return (
    <Tooltip
      title={tooltipContent}
      disableHoverListener={!hoverStatus}
    >
      <Box
        ref={inputRef}
        sx={sx.tooltipContainer}
      >
        {children}
      </Box>
    </Tooltip>
  );
};

const sx: SxStyles = {
  copyButton: {
    padding: "5px",
    marginLeft: "5px",
    background: "rgba(0,0,0,0.3)",
    borderRadius: "4px",
  },
  tooltip: {
    fontSize: "12px",
  },
  tooltipContainer: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
