import { DataGrid } from "@mui/x-data-grid";
import { Paper, Box, Button } from "@mui/material";
import { Container } from "../../../components/Container/Container";
import { Header } from "../../../components/Header/Header";
import { useEffect, useState } from "react";
import { formatGeneralRecommendations, useColumns } from "./helpers";
import { ContentFormDialog } from "../../../components/ContentFormDialog/ContentFormDialog";
import {
  ContentFormData,
  FetchResult,
} from "../../../components/ContentFormDialog/ContentFormDialog.types";
import {
  ContentSortField,
  Language,
  SortDirection,
  UpdateGeneralRecommendationInput,
  useCreateGeneralRecommendationMutation,
  useGeneralRecommendationsQuery,
  useUpdateGeneralRecommendationMutation,
} from "../../../graphql/client";
import { LanguageOptions } from "../../../hooks/types";
import { useHandleError } from "../../../hooks/useHandleError";
import { useServerPagination } from "../../../hooks/useDataGrid";
import { useForm } from "react-hook-form";
import { TableFilters } from "../../../components/TableFilters/TableFilters";
import { sx } from "../../../helpers/sx";
import { LanguageSelect } from "../../../components/LanguageSelect";
import { GeneralRecommendationDataModel } from "./PageGeneralRecommendations.types";
import { useHandleMutationResponse } from "../../../hooks/useHandleMutationResponse";
import { handleChangeSortDirection } from "../../../helpers/sortHandler";

export const PageGeneralRecommendations = () => {
  const [pageSize, setPageSize] = useState(8);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editData, setEditData] = useState<ContentFormData | null>(null);
  const [pageCursor, setPageCursor] = useState<string | null>(null);
  const [fetchResult, setFetchResult] = useState<FetchResult>(FetchResult.none);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Desc);

  const handleError = useHandleError();

  const { control, watch } = useForm<LanguageOptions>({
    defaultValues: {
      language: Language.English,
    },
  });

  const { data, refetch, isFetching } = useGeneralRecommendationsQuery(
    {
      sortDefinition: {
        contentSortField: ContentSortField.Priority,
        sortDirection: sortDirection,
      },
      meta: {
        pageSize: pageSize,
        pageCursor: pageCursor,
      },
    },
    { select: formatGeneralRecommendations, onError: handleError, keepPreviousData: true }
  );

  const { handleMutationSuccess, handleMutationError } = useHandleMutationResponse();

  const changeFetchResult = (result: FetchResult) => {
    setFetchResult(result);
  };

  const { mutate: createMutate, isLoading: isLoadingCreate } =
    useCreateGeneralRecommendationMutation({
      onError: (e: Error) => handleMutationError(e, changeFetchResult),
      onSuccess: () => handleMutationSuccess(refetch, "Recommendation created", changeFetchResult),
    });

  const { mutate: updateMutate, isLoading: isLoadingUpdate } =
    useUpdateGeneralRecommendationMutation({
      onError: (e: Error) => handleMutationError(e, changeFetchResult),
      onSuccess: () => handleMutationSuccess(refetch, "Recommendation updated", changeFetchResult),
    });

  const { handlePageChange, currentCursor, page, resetPages } = useServerPagination(isFetching);

  useEffect(() => {
    setPageCursor(currentCursor);
  }, [currentCursor]);

  const handleStartEdit = (questionObj: ContentFormData) => {
    setEditData(questionObj);
    handleOpenDialog();
  };

  const columns = useColumns(handleStartEdit, watch("language"), refetch);

  const handleDisableEdit = () => {
    setEditData(null);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleFetch = async ({
    englishFieldFirst,
    englishFieldSecond,
    icelandicFieldFirst,
    icelandicFieldSecond,
    priority,
  }: ContentFormData) => {
    if (editData && editData.id) {
      const recommendation: UpdateGeneralRecommendationInput = {
        id: editData.id,
        englishAdvice: englishFieldFirst,
        englishHint: englishFieldSecond || "",
        icelandicAdvice: icelandicFieldFirst,
        icelandicHint: icelandicFieldSecond || "",
        priority: Number(priority),
      };
      updateMutate({ input: recommendation });
    } else {
      const recommendation: GeneralRecommendationDataModel = {
        englishAdvice: englishFieldFirst,
        englishHint: englishFieldSecond || "",
        icelandicAdvice: icelandicFieldFirst,
        icelandicHint: icelandicFieldSecond || "",
        priority: Number(priority),
      };
      createMutate({ input: recommendation });
    }
  };

  const clearFetchResult = () => {
    setFetchResult(FetchResult.none);
  };

  return (
    <Container>
      <Header>General Recommendations</Header>
      <ContentFormDialog
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        editData={editData}
        contentName="Recommendation"
        firstLabel="Advice"
        secondLabel="Hint"
        handleFetch={handleFetch}
        fetchResult={fetchResult}
        clearFetchResult={clearFetchResult}
        isLoading={isLoadingCreate || isLoadingUpdate}
      />
      <Paper sx={sx.table}>
        <Box>
          <Button
            onClick={() => {
              handleDisableEdit();
              handleOpenDialog();
            }}
          >
            Create new Recommendation
          </Button>
        </Box>
        <TableFilters>
          <LanguageSelect control={control} />
        </TableFilters>
        <DataGrid
          sortingMode="server"
          onColumnHeaderClick={(event) =>
            handleChangeSortDirection(event, setSortDirection, resetPages)
          }
          onSortModelChange={resetPages}
          columns={columns}
          rows={data?.rows || []}
          paginationMode="server"
          pageSize={pageSize}
          rowCount={data?.meta.totalCount || 0}
          onPageChange={(newPage) => {
            handlePageChange(newPage, data?.meta.nextPageCursor || "");
          }}
          page={page}
          rowsPerPageOptions={[8, 16, 32]}
          onPageSizeChange={handlePageSizeChange}
          disableSelectionOnClick
          disableColumnMenu
          loading={isFetching}
          disableVirtualization
        />
      </Paper>
    </Container>
  );
};
