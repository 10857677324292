import { BrowserRouter } from "react-router-dom";
import "./styles/index.scss";
import { Navbar } from "./components/Navbar/Navbar";
import { Box } from "@mui/material";
import { colors, SxStyles } from "./theme";
import { AlertContextProvider } from "./contexts/AlertContext/AlertContext";
import { ConfirmationDialogProvider } from "./contexts/ConfirmationDialog/ConfirmationDialog";
import { AuthContextProvider } from "./contexts/AuthContext/AuthContext";
import { Router } from "./Router";

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <AlertContextProvider>
          <ConfirmationDialogProvider>
            <Navbar />
            <Box sx={sx.appContainer}>
              <Box sx={sx.contentWrapper}>
                <Router />
              </Box>
            </Box>
          </ConfirmationDialogProvider>
        </AlertContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

const sx: SxStyles = {
  appContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    height: "100vh",
    py: "100px",
    px: "50px",
    background: colors.backgroundGradient,
    overflowX: "hidden",
  },
  contentWrapper: {
    maxHeight: "100%",
    width: "100%",
    maxWidth: "1200px",
  },
};

export default App;
