import React, { createContext, useContext, useRef, useState } from "react";
import { AlertDialog } from "../../components/AlertDialog/AlertDialog";
import { ConfirmationDialogProvProps, ConfirmationOptions } from "./ConfirmationDialog.types";

const ConfirmationDialogContext = createContext<(options: ConfirmationOptions) => Promise<void>>(
  Promise.reject
);

export const useConfirmationDialog = () => useContext(ConfirmationDialogContext);

export const ConfirmationDialogProvider = ({ children }: ConfirmationDialogProvProps) => {
  const [confirmationState, setConfirmationState] = useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState({ open: true, ...options });
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setConfirmationState({ ...confirmationState, open: false });
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setConfirmationState({ ...confirmationState, open: false });
  };

  return (
    <>
      <ConfirmationDialogContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationDialogContext.Provider>
      <AlertDialog
        open={!!confirmationState?.open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        {...confirmationState}
      />
    </>
  );
};
