import { AUTH_TOKEN_NAME, AUTH_REFRESH_TOKEN_NAME } from "../utilities/config";

export const getAccessToken = () => {
  return localStorage.getItem(AUTH_TOKEN_NAME);
};

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(AUTH_TOKEN_NAME, accessToken);
};

export const removeAccessToken = () => {
  localStorage.removeItem(AUTH_TOKEN_NAME);
};

export const getRefreshToken = () => {
  return localStorage.getItem(AUTH_REFRESH_TOKEN_NAME);
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem(AUTH_REFRESH_TOKEN_NAME, refreshToken);
};

export const removeRefreshToken = () => {
  localStorage.removeItem(AUTH_REFRESH_TOKEN_NAME);
};
