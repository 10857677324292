import { GridColDef } from "@mui/x-data-grid";

import {
  AddVideoToTreatmentWeekInput,
  AddVideoToTreatmentWeekMutation,
  Exact,
  GetTreatmentVideoThumbnailUploadUrlQuery,
  GetTreatmentVideoUploadUrlQuery,
  Language,
  ListTreatmentsWithDetailsQuery,
  UpdateTreatmentVideoInput,
  UpdateTreatmentVideoMutation,
  useDeleteTreatmentMutation,
} from "../../graphql/client";
import { OverflowTooltip } from "../../components/OverflowTooltip/OverflowTooltip";
import SettingsIcon from "@mui/icons-material/Settings";

import { RefetchType } from "../../hooks/types";
import { columnPriority } from "../../helpers/columnDef";
import {
  TreatmentDataModel,
  TreatmentWeek,
  TreatmentWeekVideo,
  TreatmentWeekVideoDataModel,
  UseTreatmentsReturn,
} from "./PageTreatments.types";
import { MenuActions } from "../../components/MenuActions/MenuActions";
import { useConfirmationDialog } from "../../contexts/ConfirmationDialog/ConfirmationDialog";
import { MenuOption } from "../../components/MenuActions/MenuActions.types";
import { useHandleMutationResponse } from "../../hooks/useHandleMutationResponse";
import { MediaType, uploadMedia } from "../../helpers/mediaUploader";
import { Duration } from "luxon";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutationOptions,
} from "@tanstack/react-query";

export const useColumns = (
  handleStartEdit: (treatment: TreatmentDataModel) => void,
  handleOpenWeeks: (treatmentId: string, weeks: (TreatmentWeek | null)[]) => void,
  language: Language,
  refetch: RefetchType
) => {
  const confirm = useConfirmationDialog();
  const { handleMutationSuccess, handleMutationError } = useHandleMutationResponse();

  const { mutate: deleteTreatmentMutate } = useDeleteTreatmentMutation({
    onError: (e: Error) => handleMutationError(e),
    onSuccess: () => handleMutationSuccess(refetch, "Treatment deleted"),
  });

  const createOptions = (obj: TreatmentDataModel) => {
    const optionsList: MenuOption[] = [
      {
        optionText: "Edit details",
        operation: () => handleStartEdit(obj),
      },
      {
        optionText: "Edit weeks",
        operation: () => handleOpenWeeks(obj.id, obj.weeks),
      },
      {
        optionText: "Delete",
        operation: () =>
          confirm({
            title: "Delete treatment",
            text: "Are you sure?",
          }).then(() => {
            deleteTreatmentMutate({ input: { id: obj.id } });
          }),
      },
    ];

    return optionsList;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return <OverflowTooltip title={params.row.id}>{params.row.id}</OverflowTooltip>;
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        const value = params.row[`${language.toLowerCase()}Title`];
        return <OverflowTooltip title={value}>{value}</OverflowTooltip>;
      },
    },
    {
      field: "weeks",
      headerName: "Weeks",
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        const value = params.row.weeks.length;
        return <OverflowTooltip title={value}>{value}</OverflowTooltip>;
      },
    },
    columnPriority,
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <MenuActions
            menuIcon={SettingsIcon}
            options={createOptions(params.row)}
            id={params.row.id}
          />
        );
      },
    },
  ];

  return columns;
};

export const formatTreatments = (data: ListTreatmentsWithDetailsQuery): UseTreatmentsReturn => {
  const treatments = data.content.listTreatmentsWithDetails.items;
  let treatmentObj: TreatmentDataModel;
  let rowsArray: TreatmentDataModel[] = treatments?.map((treatment) => {
    if (treatment.details[0]?.language === Language.English) {
      treatmentObj = {
        id: treatment.id.toString(),
        englishTitle: treatment.details[0]?.title,
        icelandicTitle: treatment.details[1]?.title || "",
        englishDescription: treatment.details[0]?.description || "",
        icelandicDescription: treatment.details[1]?.description || "",
        priority: treatment.priority,
        weeks: treatment.weeks,
      };
    } else {
      treatmentObj = {
        id: treatment.id.toString(),
        englishTitle: treatment.details[1]?.title || "",
        icelandicTitle: treatment.details[0]?.title || "",
        englishDescription: treatment.details[1]?.description || "",
        icelandicDescription: treatment.details[0]?.description || "",
        priority: treatment.priority,
        weeks: treatment.weeks,
      };
    }

    return treatmentObj;
  });

  return { rows: rowsArray, meta: data.content.listTreatmentsWithDetails.meta };
};

export const formatVideoDuration = (element: HTMLVideoElement) => {
  return new Promise<string>((resolve, reject) => {
    element.onloadedmetadata = async function () {
      const duration = element.duration;
      const days = Math.floor(duration / 86400);
      const hours = Math.floor(duration / 3600) % 24;
      const minutes = Math.floor(duration / 60) % 60;
      const seconds = Math.floor(duration / 1) % 60;
      const milliseconds = Math.floor(duration * 0.001) % 1000;

      const durationObj = Duration.fromObject({
        day: days,
        hour: hours,
        minute: minutes,
        second: seconds,
        millisecond: milliseconds,
      });

      const formattedDuration = durationObj.toFormat("dd:hh:mm:ss.SS");

      resolve(formattedDuration);
    };
  });
};

export const handleVideoFormSubmit = async (
  editData: TreatmentWeekVideoDataModel | null,
  createMutate: (
    variables: Exact<{
      input: AddVideoToTreatmentWeekInput;
    }>,
    options?:
      | UseMutationOptions<
          AddVideoToTreatmentWeekMutation,
          unknown,
          Exact<{
            input: AddVideoToTreatmentWeekInput;
          }>,
          unknown
        >
      | undefined
  ) => void,
  updateMutate: (
    variables: Exact<{
      input: UpdateTreatmentVideoInput;
    }>,
    options?:
      | UseMutationOptions<
          UpdateTreatmentVideoMutation,
          unknown,
          Exact<{
            input: UpdateTreatmentVideoInput;
          }>,
          unknown
        >
      | undefined
  ) => void,
  videoThumbnailUploadUrlData: GetTreatmentVideoThumbnailUploadUrlQuery,
  videoUploadUrlData: GetTreatmentVideoUploadUrlQuery,
  data: TreatmentWeekVideo,
  refetchVideoUploadUrl: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult>,
  refetchThumbnailVideoUploadUrl: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult>
) => {
  const englishVideoThumbnail = data.englishVideoThumbnail?.[0];
  const icelandicVideoThumbnail = data.icelandicVideoThumbnail?.[0];
  const englishVideo = data.englishVideo?.[0];
  const icelandicVideo = data.icelandicVideo?.[0];
  let thumbnailCreateUrlEnglish = "";
  let thumbnailCreateUrlIcelandic = "";
  let videoCreateUrlEnglish = "";
  let videoCreateUrlIcelandic = "";
  const firstThumbnailUploadUrl =
    videoThumbnailUploadUrlData?.content.getTreatmentVideoThumbnailUploadUrl.url;
  const firstVideoUploadUrl = videoUploadUrlData?.content.getTreatmentVideoUploadUrl.url;
  let urls;
  if (englishVideoThumbnail.size > 0) {
    if (firstThumbnailUploadUrl) {
      urls = await uploadMedia<GetTreatmentVideoThumbnailUploadUrlQuery>(
        MediaType.image,
        englishVideoThumbnail,
        firstThumbnailUploadUrl,
        refetchThumbnailVideoUploadUrl
      );
    }
    thumbnailCreateUrlEnglish = urls?.mediaUrl || "";
  }
  if (icelandicVideoThumbnail.size > 0) {
    if (urls?.uploadUrl?.content?.getTreatmentVideoThumbnailUploadUrl?.url) {
      const newUploadUrl = urls?.uploadUrl.content.getTreatmentVideoThumbnailUploadUrl.url;

      urls = await uploadMedia<GetTreatmentVideoThumbnailUploadUrlQuery>(
        MediaType.image,
        icelandicVideoThumbnail,
        newUploadUrl,
        refetchThumbnailVideoUploadUrl
      );
    } else if (firstThumbnailUploadUrl) {
      urls = await uploadMedia<GetTreatmentVideoThumbnailUploadUrlQuery>(
        MediaType.image,
        icelandicVideoThumbnail,
        firstThumbnailUploadUrl,
        refetchThumbnailVideoUploadUrl
      );
    }
    thumbnailCreateUrlIcelandic = urls?.mediaUrl || "";
  }
  if (englishVideo.size > 0) {
    if (firstVideoUploadUrl) {
      urls = await uploadMedia<GetTreatmentVideoUploadUrlQuery>(
        MediaType.video,
        englishVideo,
        firstVideoUploadUrl,
        refetchVideoUploadUrl
      );
    }
    videoCreateUrlEnglish = urls?.mediaUrl || "";
  }
  if (icelandicVideo.size > 0) {
    if (urls?.uploadUrl as GetTreatmentVideoUploadUrlQuery) {
      const newUploadUrl = (urls?.uploadUrl as GetTreatmentVideoThumbnailUploadUrlQuery).content
        .getTreatmentVideoThumbnailUploadUrl.url;

      urls = await uploadMedia<GetTreatmentVideoUploadUrlQuery>(
        MediaType.video,
        icelandicVideo,
        newUploadUrl,
        refetchVideoUploadUrl
      );
    } else if (firstVideoUploadUrl) {
      urls = await uploadMedia<GetTreatmentVideoUploadUrlQuery>(
        MediaType.video,
        icelandicVideo,
        firstVideoUploadUrl,
        refetchVideoUploadUrl
      );
    }
    videoCreateUrlIcelandic = urls?.mediaUrl || "";
  }
  let formatedVidDurationEng = "";
  let formatedVidDurationIce = "";
  const videoEng = document.createElement("video");
  const videoIce = document.createElement("video");

  videoEng.src = videoCreateUrlEnglish;
  videoIce.src = videoCreateUrlIcelandic;

  if (editData) {
    const {
      englishVideoThumbnail,
      englishVideo,
      icelandicVideoThumbnail,
      icelandicVideo,
      englishVideoDuration,
      icelandicVideoDuration,
      priority,
      weekId,
      ...rest
    } = data;
    const mutate = () =>
      updateMutate({
        input: {
          videoId: editData.videoId,
          englishVideoThumbnailUrl: thumbnailCreateUrlEnglish || editData.englishVideoThumbnailUrl,
          englishVideoUrl: videoCreateUrlEnglish || editData.englishVideoUrl,
          englishVideoDuration: formatedVidDurationEng || editData.englishVideoDuration,
          icelandicVideoThumbnailUrl:
            thumbnailCreateUrlIcelandic || editData.icelandicVideoThumbnailUrl,
          icelandicVideoUrl: videoCreateUrlIcelandic || editData.icelandicVideoUrl,
          icelandicVideoDuration: formatedVidDurationIce || icelandicVideoDuration,
          priority: Number(priority),
          ...rest,
        },
      });

    if (videoCreateUrlEnglish) {
      formatedVidDurationEng = await formatVideoDuration(videoEng);
      if (!videoCreateUrlIcelandic) {
        mutate();
      }
    }
    if (videoCreateUrlIcelandic) {
      formatedVidDurationIce = await formatVideoDuration(videoIce);
      mutate();
    }
    if (!videoCreateUrlEnglish && !videoCreateUrlIcelandic) {
      mutate();
    }
  } else {
    if (
      thumbnailCreateUrlEnglish &&
      thumbnailCreateUrlIcelandic &&
      videoCreateUrlEnglish &&
      videoCreateUrlIcelandic
    ) {
      const {
        englishVideoThumbnail,
        englishVideo,
        icelandicVideoThumbnail,
        icelandicVideo,
        icelandicVideoDuration,
        englishVideoDuration,
        priority,
        ...rest
      } = data;

      const [formatedVidDurationEng, formatedVidDurationIce] = await Promise.all([
        formatVideoDuration(videoEng),
        formatVideoDuration(videoIce),
      ]);

      createMutate({
        input: {
          englishVideoThumbnailUrl: thumbnailCreateUrlEnglish,
          englishVideoUrl: videoCreateUrlEnglish,
          icelandicVideoThumbnailUrl: thumbnailCreateUrlIcelandic,
          icelandicVideoUrl: videoCreateUrlIcelandic,
          englishVideoDuration: formatedVidDurationEng,
          icelandicVideoDuration: formatedVidDurationIce,
          priority: Number(priority),
          ...rest,
        },
      });
    }
  }
};
