import { useCallback, useState } from "react";

export const useServerPagination = (isLoading: boolean) => {
  const [cursors, setCursors] = useState<Array<string | null>>([null]);
  const [page, setPage] = useState(0);
  const [currentCursor, setCurrentCursor] = useState<string | null>(null);

  const resetPages = () => {
    setPage(0);
    setCurrentCursor(null);
    setCursors([null]);
  };

  const handlePageChange = useCallback(
    (newPage: number, nextPageCursor: string | undefined) => {
      if (!isLoading) {
        if (newPage > page && nextPageCursor) {
          if (!cursors[newPage]) {
            cursors.push(nextPageCursor);
          }
          setCurrentCursor(nextPageCursor);
        } else if (page > 0) {
          setCurrentCursor(cursors[newPage]);
        }
        setPage(newPage);
      }
    },
    [cursors, page, isLoading]
  );

  return { currentCursor, handlePageChange, resetPages, page };
};
