import { AppBar, Button, Container, Toolbar } from "@mui/material";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext/AuthContext";
import { routes } from "../../utilities/routes";
import { Logo } from "../Logo/Logo";
import { SxStyles } from "../../theme";
import { MenuMobileWrapper } from "./MenuMobileWrapper";
import { MenuDesktopWrapper } from "./MenuDesktopWrapper";

export const Navbar = () => {
  const auth = useAuthContext();
  const { isLoggedIn } = auth;
  const pages = [
    { label: "User management", path: routes.userManagement() },
    { label: "Education materials", path: routes.edu().init },
    { label: "Content", path: routes.content().init },
    { label: "Relax", path: routes.sleepAidMaterials() },
    { label: "Treatments", path: routes.treatments() },
  ];

  const LogoDesktop = () => (
    <Link
      component={RouterLink}
      to={routes.home()}
      sx={sx.logoDesktop}
    >
      <Logo height="20px" />
    </Link>
  );

  const LogoMobile = () => (
    <Link
      component={RouterLink}
      to={routes.home()}
      sx={sx.logoMobile}
    >
      <Logo height="20px" />
    </Link>
  );

  return (
    <AppBar
      position="fixed"
      sx={sx.appBar}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={sx.toolBar}
        >
          <LogoDesktop />
          {isLoggedIn && <MenuMobileWrapper pages={pages} />}
          <LogoMobile />
          {isLoggedIn && <MenuDesktopWrapper pages={pages} />}
          {isLoggedIn ? (
            <Button
              variant="text"
              component={RouterLink}
              sx={sx.link}
              to="/login"
              onClick={() => {
                auth.logout();
              }}
            >
              Logout
            </Button>
          ) : (
            <Button
              variant="text"
              component={RouterLink}
              sx={sx.link}
              to="/login"
            >
              Login
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const sx: SxStyles = {
  appBar: { backgroundColor: "background.default" },
  toolBar: { display: "flex", justifyContent: "space-between" },
  logoMobile: {
    display: { xs: "flex", lg: "none" },
    flexGrow: 1,
    color: "common.white",
    textDecoration: "none",
  },
  logoDesktop: {
    mr: 2,
    display: { xs: "none", lg: "flex" },
    textDecoration: "none",
    color: "common.white",
  },
  link: { textDecoration: "none", my: 2, color: "common.white" },
};
