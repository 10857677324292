import { SxStyles } from "../theme";

export const TABLE_HEIGHT = 620;

export const sx: SxStyles = {
  table: {
    display: "flex",
    flexDirection: "column",
    height: `${TABLE_HEIGHT}px`,
    maxHeight: "100%",
    padding: "25px",
    gap: "10px",
  },
  contentButton: {
    color: "common.black",
    fontsize: "0.875rem",
  },
  cancelButton: {
    color: "common.black",
    "&:hover": { textDecoration: "underline" },
    fontWeight: 600,
  },
  image: { height: "45px", width: "80px", objectFit: "cover" },
  priorityInput: { width: "150px", marginTop: "25px" },
};
