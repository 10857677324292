import { Paper, Box, Button } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Header } from "../../components/Header/Header";
import { Container } from "../../components/Container/Container";
import { PostDialog } from "./PostModals";
import { TableFilters } from "../../components/TableFilters/TableFilters";
import { CategoryNames, PostDataModel, UseEducationPostsReturn } from "./PageEduPosts.types";
import { useForm } from "react-hook-form";
import { formatEducationPosts, useColumns } from "./helpers";
import {
  ContentSortField,
  Language,
  SortDirection,
  useCategoriesQuery,
  useEducationPostsQuery,
} from "../../graphql/client";
import { useServerPagination } from "../../hooks/useDataGrid";
import { LanguageOptions } from "../../hooks/types";
import { useHandleError } from "../../hooks/useHandleError";
import { LanguageSelect } from "../../components/LanguageSelect";
import { sx } from "../../helpers/sx";
import { formatEducationCategories } from "../PageEduCategories/helpers";
import { handleChangeSortDirection } from "../../helpers/sortHandler";

export function PageEduPosts() {
  const [pageSize, setPageSize] = useState(8);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editData, setEditData] = useState<PostDataModel | null>(null);
  const { watch, control } = useForm<LanguageOptions>({
    defaultValues: {
      language: Language.English,
    },
  });
  const handleError = useHandleError();

  const [pageCursor, setPageCursor] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Desc);

  const { data, isFetching, refetch } = useEducationPostsQuery<UseEducationPostsReturn, Error>(
    {
      sortDefinition: {
        contentSortField: ContentSortField.Priority,
        sortDirection: sortDirection,
      },
      meta: {
        pageSize: pageSize,
        pageCursor: pageCursor,
      },
    },
    { select: formatEducationPosts, keepPreviousData: true, onError: (e) => handleError(e) }
  );

  const { data: categories } = useCategoriesQuery(
    {},
    {
      select: formatEducationCategories,
      onError: (e: Error) => handleError(e),
    }
  );

  const categoriesMap = useMemo(() => {
    const map = new Map();

    categories?.rows.forEach((category) => {
      const names: CategoryNames = {
        englishName: category.englishName,
        icelandicName: category.icelandicName,
      };
      map.set(category.id, names);
    });

    return map;
  }, [categories]);

  const { handlePageChange, currentCursor, page, resetPages } = useServerPagination(isFetching);

  useEffect(() => {
    setPageCursor(currentCursor);
  }, [currentCursor]);

  const handleStartEdit = (obj: PostDataModel) => {
    setEditData(obj);
    handleOpenDialog();
  };

  const columns = useColumns(handleStartEdit, watch("language"), refetch, categoriesMap);

  const handleDisableEdit = () => {
    setEditData(null);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Container>
      <Header>Education Posts</Header>
      <PostDialog
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        editData={editData}
        refetch={refetch}
      />
      <Paper sx={sx.table}>
        <Box sx={sx.tableButtons}>
          <Button
            onClick={() => {
              handleDisableEdit();
              handleOpenDialog();
            }}
          >
            Add new post
          </Button>
        </Box>
        <TableFilters>
          <LanguageSelect control={control} />
        </TableFilters>
        <DataGrid
          sortingMode="server"
          onColumnHeaderClick={(event) =>
            handleChangeSortDirection(event, setSortDirection, resetPages)
          }
          onSortModelChange={resetPages}
          columns={columns}
          rows={data?.rows || []}
          paginationMode="server"
          pageSize={pageSize}
          rowCount={data?.meta.totalCount || 0}
          rowsPerPageOptions={[8, 16, 32]}
          onPageChange={(newPage) => {
            handlePageChange(newPage, data?.meta.nextPageCursor || "");
          }}
          page={page}
          onPageSizeChange={handlePageSizeChange}
          disableSelectionOnClick
          disableColumnMenu
          loading={isFetching}
          disableVirtualization
        />
      </Paper>
    </Container>
  );
}
