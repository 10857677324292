import { GridColumnHeaderParams, GridValidRowModel } from "@mui/x-data-grid";
import { Dispatch } from "react";
import { ContentSortField, SortDirection } from "../graphql/client";

export const handleChangeSortDirection = (
  event: GridColumnHeaderParams<any, GridValidRowModel, any>,
  setSortDirection: Dispatch<React.SetStateAction<SortDirection>>,
  resetPages: () => void
) => {
  const name = event.colDef.field.toUpperCase();

  if (name === ContentSortField.Priority) {
    setSortDirection((prev) =>
      prev === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc
    );
    resetPages();
  }
};
