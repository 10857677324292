import { GridColDef } from "@mui/x-data-grid";
import { MenuActions } from "../../components/MenuActions/MenuActions";
import { MenuOption } from "../../components/MenuActions/MenuActions.types";
import { useConfirmationDialog } from "../../contexts/ConfirmationDialog/ConfirmationDialog";
import SettingsIcon from "@mui/icons-material/Settings";
import { TextContentButton } from "../../components/TextContentButton/TextContentButton";
import {
  CategoryNames,
  PostDataModel,
  UseEducationCategoriesReturn,
  UseEducationPostsReturn,
} from "./PageEduPosts.types";
import {
  EducationPostsQuery,
  Language,
  ListCategoriesQuery,
  useDeleteEducationPostMutation,
} from "../../graphql/client";
import { OverflowTooltip } from "../../components/OverflowTooltip/OverflowTooltip";
import { useHandleMutationResponse } from "../../hooks/useHandleMutationResponse";
import { RefetchType } from "../../hooks/types";
import { columnPriority } from "../../helpers/columnDef";

export const useColumns = (
  handleStartEdit: (obj: PostDataModel) => void,
  language: Language,
  refetch: RefetchType,
  categoriesMap: Map<string, CategoryNames>
) => {
  const confirm = useConfirmationDialog();
  const { handleMutationSuccess, handleMutationError } = useHandleMutationResponse();

  const { mutate: deleteMutate } = useDeleteEducationPostMutation({
    onError: (e: Error) => handleMutationError(e),
    onSuccess: () => handleMutationSuccess(refetch, "Post deleted"),
  });

  const createOptions = (obj: PostDataModel) => {
    const optionsList: MenuOption[] = [
      {
        optionText: "Edit",
        operation: () => handleStartEdit(obj),
      },
      {
        optionText: "Delete",
        operation: (id: string) =>
          confirm({
            title: "Delete post",
            text: "Are you sure?",
          }).then(() => {
            deleteMutate({ input: { id: obj.id } });
          }),
      },
    ];

    return optionsList;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return <OverflowTooltip title={params.row.id}>{params.row.id}</OverflowTooltip>;
      },
    },
    {
      field: "category",
      headerName: "Category",
      sortable: false,
      minWidth: 150,
      renderCell: (params) => {
        const names: CategoryNames = categoriesMap?.get(params.row.basicEducationCategoryId) || {
          englishName: "",
          icelandicName: "",
        };
        const name = names[`${language.toLowerCase()}Name` as keyof CategoryNames];

        return <OverflowTooltip title={name}>{name}</OverflowTooltip>;
      },
    },
    {
      field: "title",
      headerName: "Title",
      sortable: false,
      minWidth: 150,
      renderCell: (params) => {
        const value = params.row[`${language.toLowerCase()}Title`];
        return <OverflowTooltip title={value}>{value}</OverflowTooltip>;
      },
    },
    {
      field: "content",
      headerName: "Content",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        const value = params.row[`${language.toLowerCase()}Content`];
        return <TextContentButton content={value} />;
      },
    },
    columnPriority,
    {
      field: "actions",
      headerName: "",
      width: 100,
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <MenuActions
            menuIcon={SettingsIcon}
            options={createOptions(params.row)}
            id={params.row.id}
          />
        );
      },
    },
  ];

  return columns;
};

export const formatEducationPosts = (data: EducationPostsQuery): UseEducationPostsReturn => {
  const posts = data.content.listBasicEducationPostsWithDetails.items;

  const rowsArray: PostDataModel[] = posts.map((post) => {
    let postObj: PostDataModel;

    if (post.details[0]?.language === Language.English) {
      postObj = {
        id: post.id.toString(),
        basicEducationCategoryId: post.basicEducationCategoryId,
        englishTitle: post.details[0]?.title,
        englishContent: post.details[0]?.content,
        icelandicTitle: post.details[1]?.title || "",
        icelandicContent: post.details[1]?.content || "",
        priority: post.priority,
      };
    } else {
      postObj = {
        id: post.id.toString(),
        basicEducationCategoryId: post.basicEducationCategoryId,
        englishTitle: post.details[1]?.title || "",
        englishContent: post.details[1]?.content || "",
        icelandicTitle: post.details[0]?.title || "",
        icelandicContent: post.details[0]?.content || "",
        priority: post.priority,
      };
    }

    return postObj;
  });

  return { rows: rowsArray, meta: data.content.listBasicEducationPostsWithDetails.meta };
};

export const formatEducationCategories = (
  data: ListCategoriesQuery
): UseEducationCategoriesReturn => {
  return data.content.listBasicEducationCategories.items;
};
