import { Box, IconButton, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuList from "@mui/material/MenuList";
import { Link as RouterLink } from "react-router-dom";
import { CreateMenuItemProps, MenuWrapperProps } from "./MenuWrapper.types";
import { SxStyles } from "../../theme";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import { SyntheticEvent, useRef, useState } from "react";
import { routes } from "../../utilities/routes";
import { ExpandMenuButtonMobile } from "./ExpandMenuButtons/ExpandMenuButtonMobile";

export const MenuMobileWrapper = ({ pages }: MenuWrapperProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseNavMenu = (event: Event | SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={sx.menuMobileWrapper}>
      <IconButton
        size="large"
        sx={sx.menuIcon}
        ref={anchorRef}
        onClick={handleToggle}
      >
        <MenuIcon />
      </IconButton>
      <ClickAwayListener onClickAway={handleCloseNavMenu!}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          placement="top-start"
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "left top",
              }}
            >
              <Paper sx={sx.menuMobile}>
                <MenuList>
                  {pages.map((page) => (
                    <CreateMenuItem
                      key={page.path}
                      page={page}
                      handleCloseNavMenu={handleCloseNavMenu}
                    />
                  ))}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};

const CreateMenuItem = ({ page, handleCloseNavMenu }: CreateMenuItemProps) => {
  switch (page.path) {
    case routes.edu().init:
      return (
        <ExpandMenuButtonMobile
          page={page}
          closeMobileMenu={handleCloseNavMenu}
          pageName={routes.edu().init}
        />
      );

    case routes.content().init:
      return (
        <ExpandMenuButtonMobile
          page={page}
          closeMobileMenu={handleCloseNavMenu}
          pageName={routes.content().init}
        />
      );
    default:
      return (
        <MenuItem
          component={RouterLink}
          to={page.path}
          sx={sx.menuMobileLink}
          onClick={handleCloseNavMenu}
        >
          {page.label}
        </MenuItem>
      );
  }
};

const sx: SxStyles = {
  menuMobileWrapper: {
    flexGrow: 1,
    display: { xs: "flex", lg: "none" },
  },
  menuMobile: { display: { xs: "block", lg: "none" }, minWidth: "240px" },
  menuMobileLink: {
    display: "flex",
    textDecoration: "none",
    color: "common.black",
  },
  menuIcon: { color: "common.white" },
};
