import { Dialog as MuiDialog, DialogTitle } from "@mui/material";
import { DialogProps } from "./Dialog.types";

export const Dialog = ({ title = "", sx, size = "lg", children, ...rest }: DialogProps) => {
  return (
    <MuiDialog
      sx={{
        mx: "auto",
        width: "100%",
        maxWidth: {
          xs: "400px",
          md: size === "sm" ? "400px" : size === "md" ? "650px" : "1000px",
        },
        ...sx,
      }}
      {...rest}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {children}
    </MuiDialog>
  );
};
