import { GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { MenuActions } from "../../components/MenuActions/MenuActions";
import { MenuOption } from "../../components/MenuActions/MenuActions.types";
import { useConfirmationDialog } from "../../contexts/ConfirmationDialog/ConfirmationDialog";
import { TextContentButton } from "../../components/TextContentButton/TextContentButton";
import {
  SleepAidPost,
  SleepAidPostDataModel,
  UseSleepAidPostsReturn,
  Video,
} from "./PageSleepAidPosts.types";
import {
  CreateSleepAidPostInput,
  CreateSleepAidPostMutation,
  Exact,
  GetVideoThumbnailUploadUrlQuery,
  GetVideoUploadUrlQuery,
  Language,
  SleepAidPostsQuery,
  UpdateSleepAidPostInput,
  UpdateSleepAidPostMutation,
  useDeleteSleepAidPostMutation,
} from "../../graphql/client";
import { OverflowTooltip } from "../../components/OverflowTooltip/OverflowTooltip";
import { Duration } from "luxon";
import { CSSProperties } from "react";
import { MediaType, uploadMedia } from "../../helpers/mediaUploader";
import {
  MutateOptions,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { sx } from "../../helpers/sx";
import { useHandleMutationResponse } from "../../hooks/useHandleMutationResponse";
import { RefetchType } from "../../hooks/types";
import { columnPriority } from "../../helpers/columnDef";

export const useColumns = (
  handleStartEdit: (obj: SleepAidPostDataModel) => void,
  handleOpenVideoModal: ({ url, title }: Video) => void,
  language: Language,
  refetch: RefetchType
) => {
  const confirm = useConfirmationDialog();
  const { handleMutationSuccess, handleMutationError } = useHandleMutationResponse();

  const { mutate: deleteMutate } = useDeleteSleepAidPostMutation({
    onError: (e: Error) => handleMutationError(e),
    onSuccess: () => handleMutationSuccess(refetch, "Post deleted"),
  });

  const createOptions = (obj: SleepAidPostDataModel) => {
    const optionsList: MenuOption[] = [
      {
        optionText: "Edit",
        operation: () => handleStartEdit(obj),
      },
      {
        optionText: "Delete",
        operation: () =>
          confirm({
            title: "Delete post",
            text: "Are you sure?",
          }).then(() => {
            deleteMutate({ input: { id: obj.id } });
          }),
      },
    ];
    return optionsList;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return <OverflowTooltip title={params.row.id}>{params.row.id}</OverflowTooltip>;
      },
    },
    {
      field: "title",
      headerName: "Title",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        const value = params.row[`${language.toLowerCase()}Title`];
        return <OverflowTooltip title={value}>{value}</OverflowTooltip>;
      },
    },
    {
      field: "video",
      headerName: "Video",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const url = params.row[`${language.toLowerCase()}VideoUrl`];
        const title = params.row[`${language.toLowerCase()}Title`];
        const thumbnail = params.row[`${language.toLowerCase()}VideoThumbnailUrl`];
        return (
          <Button
            variant="text"
            onClick={() => handleOpenVideoModal({ title: title, url: url })}
          >
            <img
              src={thumbnail}
              alt="videoThumbnail"
              style={sx.image as CSSProperties}
            />
          </Button>
        );
      },
    },
    {
      field: "videoDuration",
      headerName: "Duration",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        let value = params.row[`${language.toLowerCase()}VideoDuration`];
        if (value.substring(0, 1) === "0") {
          value = value.substring(2);
        }
        if (value.substring(0, 2) === "00") {
          value = value.substring(3);
        }
        return <OverflowTooltip title={value}>{value}</OverflowTooltip>;
      },
    },
    {
      field: "content",
      headerName: "Content",
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const content = params.row[`${language.toLowerCase()}Content`];
        return <TextContentButton content={content} />;
      },
    },
    columnPriority,
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <MenuActions
            menuIcon={SettingsIcon}
            options={createOptions(params.row)}
            id={params.row.id}
          />
        );
      },
    },
  ];

  return columns;
};

export const formatSleepAidPosts = (data: SleepAidPostsQuery): UseSleepAidPostsReturn => {
  const posts = data.content.listSleepAidPostsWithDetails.items;
  let postObj: SleepAidPostDataModel;
  let rowsArray: SleepAidPostDataModel[] = posts?.map((post) => {
    if (post.details[0]?.language === Language.English) {
      postObj = {
        id: post.id.toString(),
        englishContent: post.details[0].content,
        englishTitle: post.details[0].title,
        englishVideoDuration: post.details[0].videoDuration,
        englishVideoThumbnailUrl: post.details[0].videoThumbnailUrl,
        englishVideoUrl: post.details[0].videoUrl,
        icelandicContent: post.details[1]?.content || "",
        icelandicTitle: post.details[1]?.title || "",
        icelandicVideoDuration: post.details[1]?.videoDuration || "",
        icelandicVideoThumbnailUrl: post.details[1]?.videoThumbnailUrl || "",
        icelandicVideoUrl: post.details[1]?.videoUrl || "",
        isPodcast: post.isPodcast,
        priority: post.priority,
      };
    } else {
      postObj = {
        id: post.id.toString(),
        englishContent: post.details[1]?.content || "",
        englishTitle: post.details[1]?.title || "",
        englishVideoDuration: post.details[1]?.videoDuration || "",
        englishVideoThumbnailUrl: post.details[1]?.videoThumbnailUrl || "",
        englishVideoUrl: post.details[1]?.videoUrl || "",
        icelandicContent: post.details[0]?.content || "",
        icelandicTitle: post.details[0]?.title || "",
        icelandicVideoDuration: post.details[0]?.videoDuration || "",
        icelandicVideoThumbnailUrl: post.details[0]?.videoThumbnailUrl || "",
        icelandicVideoUrl: post.details[0]?.videoUrl || "",
        isPodcast: post.isPodcast,
        priority: post.priority,
      };
    }
    return postObj;
  });

  return { rows: rowsArray, meta: data.content.listSleepAidPostsWithDetails.meta };
};

export const formatVideoDuration = (duration: number) => {
  const days = Math.floor(duration / 86400);
  const hours = Math.floor(duration / 3600) % 24;
  const minutes = Math.floor(duration / 60) % 60;
  const seconds = Math.floor(duration / 1) % 60;
  const milliseconds = Math.floor(duration * 0.001) % 1000;

  const durationObj = Duration.fromObject({
    day: days,
    hour: hours,
    minute: minutes,
    second: seconds,
    millisecond: milliseconds,
  });

  const formattedDuration = durationObj.toFormat("dd:hh:mm:ss.SS");

  return formattedDuration;
};

export const handlePostFormSubmit = async (
  editData: SleepAidPostDataModel | null,
  createMutate: (
    variables: Exact<{
      input: CreateSleepAidPostInput;
    }>,
    options?:
      | MutateOptions<
          CreateSleepAidPostMutation,
          Error,
          Exact<{
            input: CreateSleepAidPostInput;
          }>,
          unknown
        >
      | undefined
  ) => void,
  updateMutate: (
    variables: Exact<{
      input: UpdateSleepAidPostInput;
    }>,
    options?:
      | MutateOptions<
          UpdateSleepAidPostMutation,
          Error,
          Exact<{
            input: UpdateSleepAidPostInput;
          }>,
          unknown
        >
      | undefined
  ) => void,
  videoThumbnailUploadUrlData: GetVideoThumbnailUploadUrlQuery,
  videoUploadUrlData: GetVideoUploadUrlQuery,
  data: SleepAidPost,
  refetchThumbnailUploadUrl: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult>,
  refetchVideoUploadUrl: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult>
) => {
  const englishVideoThumbnail = data.englishVideoThumbnail?.[0];
  const icelandicVideoThumbnail = data.icelandicVideoThumbnail?.[0];
  const englishVideo = data.englishVideo?.[0];
  const icelandicVideo = data.icelandicVideo?.[0];
  let thumbnailCreateUrlEnglish = "";
  let thumbnailCreateUrlIcelandic = "";
  let videoCreateUrlEnglish = "";
  let videoCreateUrlIcelandic = "";
  const firstThumbnailUploadUrl =
    videoThumbnailUploadUrlData?.content.getSleepAidPostVideoUploadUrl.url;
  const firstVideoUploadUrl = videoUploadUrlData?.content.getSleepAidPostVideoUploadUrl.url;
  let urls;
  if (englishVideoThumbnail.size > 0) {
    if (firstThumbnailUploadUrl) {
      urls = await uploadMedia<GetVideoThumbnailUploadUrlQuery>(
        MediaType.image,
        englishVideoThumbnail,
        firstThumbnailUploadUrl,
        refetchThumbnailUploadUrl
      );
    }
    thumbnailCreateUrlEnglish = urls?.mediaUrl || "";
  }
  if (icelandicVideoThumbnail.size > 0) {
    if (urls?.uploadUrl) {
      const newUploadUrl = urls?.uploadUrl.content.getSleepAidPostVideoUploadUrl.url;
      urls = await uploadMedia<GetVideoThumbnailUploadUrlQuery>(
        MediaType.image,
        icelandicVideoThumbnail,
        newUploadUrl,
        refetchThumbnailUploadUrl
      );
    } else if (firstThumbnailUploadUrl) {
      urls = await uploadMedia<GetVideoThumbnailUploadUrlQuery>(
        MediaType.image,
        icelandicVideoThumbnail,
        firstThumbnailUploadUrl,
        refetchThumbnailUploadUrl
      );
    }
    thumbnailCreateUrlIcelandic = urls?.mediaUrl || "";
  }
  if (englishVideo.size > 0) {
    if (firstVideoUploadUrl) {
      urls = await uploadMedia<GetVideoThumbnailUploadUrlQuery>(
        MediaType.video,
        englishVideo,
        firstVideoUploadUrl,
        refetchVideoUploadUrl
      );
    }
    videoCreateUrlEnglish = urls?.mediaUrl || "";
  }
  if (icelandicVideo.size > 0) {
    if (urls?.uploadUrl) {
      const newUploadUrl = urls?.uploadUrl.content.getSleepAidPostVideoUploadUrl.url;
      urls = await uploadMedia<GetVideoThumbnailUploadUrlQuery>(
        MediaType.video,
        icelandicVideo,
        newUploadUrl,
        refetchVideoUploadUrl
      );
    } else if (firstVideoUploadUrl) {
      urls = await uploadMedia<GetVideoThumbnailUploadUrlQuery>(
        MediaType.video,
        icelandicVideo,
        firstVideoUploadUrl,
        refetchVideoUploadUrl
      );
    }
    videoCreateUrlIcelandic = urls?.mediaUrl || "";
  }
  let formatedVidDurationEng = "";
  let formatedVidDurationIce = "";
  const videoEng = document.createElement("video");
  const videoIce = document.createElement("video");
  if (editData) {
    const {
      englishVideoThumbnail,
      englishVideo,
      icelandicVideoThumbnail,
      icelandicVideo,
      englishVideoDuration,
      icelandicVideoDuration,
      priority,
      ...rest
    } = data;
    const mutate = () =>
      updateMutate({
        input: {
          id: editData.id,
          englishVideoThumbnailUrl: thumbnailCreateUrlEnglish || editData.englishVideoThumbnailUrl,
          englishVideoUrl: videoCreateUrlEnglish || editData.englishVideoUrl,
          englishVideoDuration: formatedVidDurationEng || editData.englishVideoDuration,
          icelandicVideoThumbnailUrl:
            thumbnailCreateUrlIcelandic || editData.icelandicVideoThumbnailUrl,
          icelandicVideoUrl: videoCreateUrlIcelandic || editData.icelandicVideoUrl,
          icelandicVideoDuration: formatedVidDurationIce || icelandicVideoDuration,
          priority: Number(priority),
          ...rest,
        },
      });
    videoEng.src = videoCreateUrlEnglish;
    videoIce.src = videoCreateUrlIcelandic;
    if (videoCreateUrlEnglish) {
      videoEng.onloadedmetadata = function () {
        formatedVidDurationEng = formatVideoDuration(videoEng.duration);
        if (!videoCreateUrlIcelandic) {
          mutate();
        }
      };
    }
    if (videoCreateUrlIcelandic) {
      videoIce.onloadedmetadata = function () {
        formatedVidDurationIce = formatVideoDuration(videoIce.duration);
        mutate();
      };
    }
    if (!videoCreateUrlEnglish && !videoCreateUrlIcelandic) {
      mutate();
    }
  } else {
    if (
      thumbnailCreateUrlEnglish &&
      thumbnailCreateUrlIcelandic &&
      videoCreateUrlEnglish &&
      videoCreateUrlIcelandic
    ) {
      const {
        englishVideoThumbnail,
        englishVideo,
        icelandicVideoThumbnail,
        icelandicVideo,
        icelandicVideoDuration,
        englishVideoDuration,
        priority,
        ...rest
      } = data;
      videoEng.src = videoCreateUrlEnglish;
      videoIce.src = videoCreateUrlIcelandic;
      videoEng.onloadedmetadata = function () {
        formatedVidDurationEng = formatVideoDuration(videoEng.duration);
      };
      videoIce.onloadedmetadata = function () {
        formatedVidDurationIce = formatVideoDuration(videoIce.duration);
        createMutate({
          input: {
            englishVideoThumbnailUrl: thumbnailCreateUrlEnglish,
            englishVideoUrl: videoCreateUrlEnglish,
            icelandicVideoThumbnailUrl: thumbnailCreateUrlIcelandic,
            icelandicVideoUrl: videoCreateUrlIcelandic,
            englishVideoDuration: formatedVidDurationEng,
            icelandicVideoDuration: formatedVidDurationIce,
            priority: Number(priority),
            ...rest,
          },
        });
      };
    }
  }
};
