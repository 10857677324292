/* eslint-disable no-unused-vars */

export enum FetchResult {
  success = "success",
  error = "error",
  none = "none",
}

export type ContentFormDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  editData: ContentFormData | null;
  handleFetch: (data: ContentFormData) => void;
  contentName: string;
  firstLabel: string;
  secondLabel?: string;
  fetchResult?: FetchResult;
  clearFetchResult?: () => void;
  isLoading?: boolean;
};

export type ContentFormData = {
  id?: string;
  englishFieldFirst: string;
  englishFieldSecond?: string;
  icelandicFieldFirst: string;
  icelandicFieldSecond?: string;
  priority: number;
};
