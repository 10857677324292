/* eslint-disable no-unused-vars */
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";

export enum MediaType {
  image = "image",
  video = "video/mp4",
}
export const uploadMedia = async <T>(
  mediaType: MediaType,
  image: File,
  uploadUrl: string,
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult>
) => {
  await fetch(new URL(uploadUrl || ""), {
    method: "PUT",
    headers: {
      "Content-Type": mediaType,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    },
    body: image,
  });

  const mediaUrl = uploadUrl?.split("?")[0];
  const newUrl = await (await refetch()).data;

  return {
    mediaUrl: mediaUrl,
    uploadUrl: newUrl as T,
  };
};
