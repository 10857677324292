import { Alert as MuiAlert, AlertColor, Snackbar } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { SxStyles } from "../../theme";
import { AlertContextProviderProps, AlertInit } from "./AlertContext.types";

export type Alert = {
  type: AlertColor;
  info: string;
  isOpen: boolean;
  handleClose: () => void;
  openSnack: ({ type, info }: AlertInit) => void;
};

export const AlertContext = createContext<Alert>({
  type: "success",
  info: "",
  isOpen: false,
  handleClose: () => {},
  openSnack: ({ type, info }: AlertInit) => {},
});

export const useAlertContext = () => useContext(AlertContext);

export const AlertContextProvider = ({ children }: AlertContextProviderProps) => {
  const [type, setType] = useState<AlertColor>("success");
  const [info, setInfo] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const openSnack = ({ type, info }: AlertInit) => {
    setType(type);
    setInfo(info);
    setIsOpen(true);
  };

  return (
    <AlertContext.Provider value={{ type, info, isOpen, handleClose, openSnack }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        sx={sx.snackbar}
      >
        <MuiAlert
          onClose={handleClose}
          severity={type}
        >
          {info}
        </MuiAlert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

const sx: SxStyles = { snackbar: { whiteSpace: "pre-wrap" } };

export default AlertContext;
