import { Box, Button, DialogActions, Stack, TextField, Typography } from "@mui/material";
import { SxStyles } from "../../theme";
import {
  TreatmentWeekSleepAdvice,
  TreatmentWeekSleepAdviceDataModel,
  TreatmentWeekVideoDataModel,
  WeekFormType,
  WeeksFormProps,
} from "./PageTreatments.types";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ValidationMessage } from "../../utilities/enums";
import { Form } from "../../components/Form/Form";
import {
  Language,
  useAddSleepAdviceToTreatmentWeekMutation,
  useDeleteTreatmentSleepAdviceMutation,
  useDeleteTreatmentVideoMutation,
  useGetTreatmentWeekWithDetailsQuery,
  useUpdateTreatmentSleepAdviceMutation,
} from "../../graphql/client";
import { LoadingButton } from "@mui/lab";
import { sx as sxStyles } from "../../helpers/sx";
import { useHandleError } from "../../hooks/useHandleError";
import { useAlertContext } from "../../contexts/AlertContext/AlertContext";
import SettingsIcon from "@mui/icons-material/Settings";
import { MenuActions } from "../../components/MenuActions/MenuActions";
import { MenuOption } from "../../components/MenuActions/MenuActions.types";
import { useConfirmationDialog } from "../../contexts/ConfirmationDialog/ConfirmationDialog";
import Divider from "@mui/material/Divider";
import { TreatmentWeekVideoForm } from "./TreatmentWeekVideoForm";

export function WeekForm({ handleClose, weekId }: WeeksFormProps) {
  const handleError = useHandleError();
  const { openSnack } = useAlertContext();
  const confirm = useConfirmationDialog();

  const [editDataVideo, setEditDataVideo] = useState<TreatmentWeekVideoDataModel | null>(null);
  const [editDataSleepAdvice, setEditDataSleepAdvice] =
    useState<TreatmentWeekSleepAdviceDataModel | null>(null);
  const [weekFormTypeOpen, setWeekFormTypeOpen] = useState<WeekFormType | null>(null);

  const {
    register: registerSleepAdvice,
    handleSubmit: handleSubmitSleepAdvice,
    setValue: setValueSleepAdvice,
    reset: resetSleepAdvice,
    formState: { errors: errorsSleepAdvice },
  } = useForm<TreatmentWeekSleepAdvice>();

  const { data: treatmentWeekWithDetails, refetch: treatmentWeekWithDetailsRefetch } =
    useGetTreatmentWeekWithDetailsQuery({
      treatmentWeekId: weekId,
    });

  const [isMutationLoading, setIsMutationLoading] = useState(false);

  const [videos, setVideos] = useState<TreatmentWeekVideoDataModel[]>([]);
  const [sleepAdvices, setSleepAdvices] = useState<TreatmentWeekSleepAdviceDataModel[]>([]);

  const { mutate: deleteVideoMutate } = useDeleteTreatmentVideoMutation({
    onError: (e: Error) => handleVideoDeleteError(e),
    onSuccess: () => handleVideoDeleteSuccess(),
  });

  const { mutate: addSleepAdviceToTreatmentWeek, isLoading: isLoadingAddSleepAdvice } =
    useAddSleepAdviceToTreatmentWeekMutation({
      onError: (e: Error) => handleSleepAdviceAddError(e),
      onSuccess: () => handleSleepAdviceAddSuccess(),
    });

  const { mutate: updateTreatmentSleepAdvice, isLoading: isLoadingUpdateSleepAdvice } =
    useUpdateTreatmentSleepAdviceMutation({
      onError: (e: Error) => handleSleepAdviceUpdateError(e),
      onSuccess: () => handleSleepAdviceUpdateSuccess(),
    });

  const { mutate: deleteSleepAdviceMutate } = useDeleteTreatmentSleepAdviceMutation({
    onError: (e: Error) => handleSleepAdviceDeleteError(e),
    onSuccess: () => handleSleepAdviceDeleteSuccess(),
  });

  const handleVideoDeleteError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to update video.");
  };

  const handleVideoDeleteSuccess = () => {
    setIsMutationLoading(false);
    openSnack({ type: "success", info: "Video deleted" });
    getVideos();
    handleCloseVideoFormModal();
  };

  const handleSleepAdviceDeleteError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to delete sleep advice.");
  };

  const handleSleepAdviceDeleteSuccess = () => {
    setIsMutationLoading(false);
    openSnack({ type: "success", info: "Sleep advice deleted" });
    getSleepAdvices();
    handleCloseSleepAdvicesForm();
  };

  const handleSleepAdviceAddError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to add sleep advice.");
  };

  const handleSleepAdviceAddSuccess = () => {
    setIsMutationLoading(false);
    openSnack({ type: "success", info: "Sleep advice added" });
    getSleepAdvices();
    handleCloseSleepAdvicesForm();
  };

  const handleSleepAdviceUpdateError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to update sleep advice.");
  };

  const handleSleepAdviceUpdateSuccess = () => {
    setIsMutationLoading(false);
    openSnack({ type: "success", info: "Sleep advice updated" });
    getSleepAdvices();
    handleCloseSleepAdvicesForm();
  };

  const handleCloseVideoFormModal = () => {
    setWeekFormTypeOpen(null);
  };

  const handleOpenVideoFormModal = () => {
    setWeekFormTypeOpen("video");
  };

  const handleCloseSleepAdvicesForm = () => {
    setWeekFormTypeOpen(null);
  };

  const handleOpenSleepAdvicesForm = () => {
    setWeekFormTypeOpen("sleepAdvice");
  };

  const handleChangeVideos = (videosData: TreatmentWeekVideoDataModel[]) => {
    setVideos(videosData);
  };

  const onSubmitSleepAdvice = handleSubmitSleepAdvice(async (data) => {
    setIsMutationLoading(true);
    const { priority, weekId, ...obj } = data;

    if (editDataSleepAdvice) {
      const formattedData = {
        priority: Number(priority),
        sleepAdviceId: editDataSleepAdvice.sleepAdviceId,
        ...obj,
      };
      updateTreatmentSleepAdvice({ input: formattedData });
    } else {
      const formattedData = {
        priority: Number(priority),
        weekId,
        ...obj,
      };
      addSleepAdviceToTreatmentWeek({ input: formattedData });
    }
  });

  useEffect(() => {
    if (editDataSleepAdvice) {
      setValueSleepAdvice("englishTitle", editDataSleepAdvice.englishTitle);
      setValueSleepAdvice("englishContent", editDataSleepAdvice.englishContent);
      setValueSleepAdvice("icelandicTitle", editDataSleepAdvice.icelandicTitle);
      setValueSleepAdvice("icelandicContent", editDataSleepAdvice.icelandicContent);
      setValueSleepAdvice("priority", editDataSleepAdvice.priority);
    }
  }, [editDataSleepAdvice, setValueSleepAdvice]);

  const getVideos = useCallback(() => {
    if (weekId) {
      treatmentWeekWithDetailsRefetch();
      const fetchedVideos = treatmentWeekWithDetails?.content?.getTreatmentWeekWithDetails?.videos;

      if (fetchedVideos && fetchedVideos?.length > 0) {
        const videosArray: TreatmentWeekVideoDataModel[] =
          fetchedVideos.map((video) => {
            const enIndex = video?.details[0]?.language === Language.English ? 0 : 1;
            const iceIndex = video?.details[0]?.language === Language.Icelandic ? 0 : 1;

            const obj: TreatmentWeekVideoDataModel = {
              videoId: video?.id || "",
              priority: Number(video?.priority) || 0,
              weekId: weekId,
              englishTitle: video?.details[enIndex]?.title || "",
              englishContent: video?.details[enIndex]?.content || "",
              englishVideoUrl: video?.details[enIndex]?.videoUrl || "",
              englishVideoThumbnailUrl: video?.details[enIndex]?.videoThumbnailUrl || "",
              englishVideoDuration: video?.details[enIndex]?.videoDuration || "",
              icelandicTitle: video?.details[iceIndex]?.title || "",
              icelandicContent: video?.details[iceIndex]?.content || "",
              icelandicVideoUrl: video?.details[iceIndex]?.videoUrl || "",
              icelandicVideoThumbnailUrl: video?.details[iceIndex]?.videoThumbnailUrl || "",
              icelandicVideoDuration: video?.details[iceIndex]?.videoDuration || "",
            };

            return obj;
          }) || [];

        setVideos(videosArray);
      } else {
        setVideos([]);
      }
    }
  }, [
    treatmentWeekWithDetails?.content?.getTreatmentWeekWithDetails?.videos,
    treatmentWeekWithDetailsRefetch,
    weekId,
  ]);

  const getSleepAdvices = useCallback(() => {
    if (weekId) {
      treatmentWeekWithDetailsRefetch();
      const fetchedSleepAdvices =
        treatmentWeekWithDetails?.content?.getTreatmentWeekWithDetails?.sleepAdvices;

      if (fetchedSleepAdvices && fetchedSleepAdvices?.length > 0) {
        const sleepAdvicesArray: TreatmentWeekSleepAdviceDataModel[] =
          fetchedSleepAdvices.map((advice) => {
            const enIndex = advice?.details[0]?.language === Language.English ? 0 : 1;
            const iceIndex = advice?.details[0]?.language === Language.Icelandic ? 0 : 1;

            const obj: TreatmentWeekSleepAdviceDataModel = {
              sleepAdviceId: advice?.id || "",
              priority: Number(advice?.priority) || 0,
              englishTitle: advice?.details[enIndex]?.title || "",
              englishContent: advice?.details[enIndex]?.content || "",
              icelandicTitle: advice?.details[iceIndex]?.title || "",
              icelandicContent: advice?.details[iceIndex]?.content || "",
            };

            return obj;
          }) || [];

        setSleepAdvices(sleepAdvicesArray);
      } else {
        setSleepAdvices([]);
      }
    }
  }, [
    treatmentWeekWithDetails?.content?.getTreatmentWeekWithDetails?.sleepAdvices,
    treatmentWeekWithDetailsRefetch,
    weekId,
  ]);

  useEffect(() => {
    getVideos();
  }, [getVideos, treatmentWeekWithDetails?.content.getTreatmentWeekWithDetails.videos, weekId]);

  useEffect(() => {
    getSleepAdvices();
  }, [
    getSleepAdvices,
    resetSleepAdvice,
    setValueSleepAdvice,
    treatmentWeekWithDetails?.content.getTreatmentWeekWithDetails.sleepAdvices,
    weekId,
  ]);

  const handleChangeEditedVideo = (videoId: string) => {
    getVideos();
    setEditDataVideo(videos.find((vid) => vid.videoId === videoId) || null);
    handleOpenVideoFormModal();
  };

  const handleChangeEditedSleepAdvice = (sleepAdviceId: string) => {
    getSleepAdvices();
    setEditDataSleepAdvice(sleepAdvices.find((adv) => adv.sleepAdviceId === sleepAdviceId) || null);
    handleOpenSleepAdvicesForm();
  };

  const createOptionsVideo = (id: string) => {
    const optionsList: MenuOption[] = [
      {
        optionText: "Edit",
        operation: () => handleChangeEditedVideo(id),
      },
      {
        optionText: "Delete",
        operation: (id: string) =>
          confirm({
            title: "Delete video",
            text: "Are you sure?",
          }).then(() => {
            deleteVideoMutate({ input: { videoId: id } });
          }),
      },
    ];

    return optionsList;
  };

  const createOptionsSleepAdvice = (id: string) => {
    const optionsList: MenuOption[] = [
      {
        optionText: "Edit",
        operation: () => handleChangeEditedSleepAdvice(id),
      },
      {
        optionText: "Delete",
        operation: (id: string) =>
          confirm({
            title: "Delete sleep advice",
            text: "Are you sure?",
          }).then(() => {
            deleteSleepAdviceMutate({ input: { sleepAdviceId: id } });
          }),
      },
    ];

    return optionsList;
  };

  return (
    <>
      {!weekFormTypeOpen && (
        <Button
          onClick={handleClose}
          sx={sx.backButton}
        >
          Back to weeks list
        </Button>
      )}
      {weekFormTypeOpen === "video" ? (
        <TreatmentWeekVideoForm
          handleClose={handleCloseVideoFormModal}
          weekId={weekId}
          editData={editDataVideo}
          treatmentWeekData={treatmentWeekWithDetails}
          refetch={treatmentWeekWithDetailsRefetch}
          handleChangeVideos={handleChangeVideos}
        />
      ) : weekFormTypeOpen === "sleepAdvice" ? (
        <Form onSubmit={onSubmitSleepAdvice}>
          <Box sx={sx.formContainer}>
            <Box sx={sx.inputContainer}>
              <Typography sx={sx.dialogLabel}>English</Typography>
              <TextField
                sx={sx.title}
                label="Title"
                variant="standard"
                placeholder="Title"
                fullWidth
                {...registerSleepAdvice("englishTitle", {
                  required: true,
                })}
                error={!!errorsSleepAdvice.englishTitle}
                helperText={!!errorsSleepAdvice.englishTitle && ValidationMessage.Required}
              />
              <TextField
                label="Content"
                variant="outlined"
                placeholder="Content"
                multiline
                rows={7}
                {...registerSleepAdvice("englishContent", {
                  required: true,
                })}
                error={!!errorsSleepAdvice.englishContent}
                helperText={!!errorsSleepAdvice.englishContent && ValidationMessage.Required}
              />
            </Box>
            <Box sx={sx.inputContainer}>
              <Typography sx={sx.dialogLabel}>Icelandic</Typography>
              <TextField
                sx={sx.title}
                label="Title"
                variant="standard"
                placeholder="Title"
                fullWidth
                {...registerSleepAdvice("icelandicTitle", {
                  required: true,
                })}
                error={!!errorsSleepAdvice.icelandicTitle}
                helperText={!!errorsSleepAdvice.icelandicTitle && ValidationMessage.Required}
              />
              <TextField
                label="Content"
                variant="outlined"
                placeholder="Content"
                multiline
                rows={7}
                {...registerSleepAdvice("icelandicContent", {
                  required: true,
                })}
                error={!!errorsSleepAdvice.icelandicContent}
                helperText={!!errorsSleepAdvice.icelandicContent && ValidationMessage.Required}
              />
            </Box>
          </Box>
          <Box sx={sx.priorityWrapper}>
            <TextField
              sx={sxStyles.priorityInput}
              label="Priority"
              variant="outlined"
              type="number"
              placeholder="0"
              {...registerSleepAdvice("priority", {
                min: 0,
              })}
              error={!!errorsSleepAdvice.priority}
              helperText={!!errorsSleepAdvice.priority && ValidationMessage.NonNegativeNumber}
            />
          </Box>
          <DialogActions>
            <Button
              onClick={() => {
                handleCloseSleepAdvicesForm();
                setIsMutationLoading(false);
              }}
              variant="text"
              sx={sxStyles.cancelButton}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoadingAddSleepAdvice || isLoadingUpdateSleepAdvice || isMutationLoading}
              disabled={isLoadingAddSleepAdvice || isLoadingUpdateSleepAdvice || isMutationLoading}
              type="submit"
              variant="contained"
            >
              {editDataSleepAdvice ? "Update" : "Create"}
            </LoadingButton>
          </DialogActions>
        </Form>
      ) : (
        <Box sx={sx.listsWrapper}>
          <Box sx={sx.videosList}>
            <Typography sx={sx.dialogLabel}>Week videos</Typography>
            <Button
              sx={sx.newItemButton}
              onClick={() => {
                setEditDataVideo(null);
                handleOpenVideoFormModal();
              }}
            >
              <Typography>Add new video</Typography>
            </Button>
            <Stack
              divider={<Divider />}
              direction="column"
              spacing={1}
              sx={sx.videoStack}
            >
              {videos.map((vid) => (
                <Box
                  sx={sx.video}
                  key={vid.videoId}
                >
                  <Typography>{vid.englishTitle}</Typography>
                  <MenuActions
                    menuIcon={SettingsIcon}
                    options={createOptionsVideo(vid.videoId)}
                    id={vid.videoId}
                  />
                </Box>
              ))}
            </Stack>
          </Box>
          <Divider orientation="vertical" />
          <Box sx={sx.sleepAdvicesListWrapper}>
            <Typography sx={sx.dialogLabel}>Sleep advices</Typography>
            <Button
              sx={sx.newItemButton}
              onClick={() => {
                setEditDataSleepAdvice(null);
                resetSleepAdvice();
                handleOpenSleepAdvicesForm();
                setValueSleepAdvice("weekId", weekId);
              }}
            >
              <Typography>Add new sleep advice</Typography>
            </Button>
            <Stack
              divider={<Divider />}
              direction="column"
              spacing={1}
              sx={sx.videoStack}
            >
              {sleepAdvices.map((adv) => (
                <Box
                  sx={sx.video}
                  key={adv.sleepAdviceId}
                >
                  <Typography>{adv.englishTitle}</Typography>
                  <MenuActions
                    menuIcon={SettingsIcon}
                    options={createOptionsSleepAdvice(adv.sleepAdviceId)}
                    id={adv.sleepAdviceId}
                  />
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
}

const sx: SxStyles = {
  dialogLabel: { fontWeight: 600 },
  formContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: "60px",
    py: "20px",
    px: "24px",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "100%",
    width: "400px",
    mx: "auto",
  },
  videosList: {
    display: "flex",
    flexDirection: "column",
    px: "24px",
    py: "24px",
    flex: "1",
  },
  video: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  videoStack: {
    marginTop: "10px",
    maxHeight: "400px",
    overflow: "auto",
  },
  newItemButton: {
    marginTop: "10px",
  },
  priorityWrapper: {
    pl: "24px",
  },
  listsWrapper: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    width: "100%",
    maxWidth: "100%",
  },
  sleepAdvicesListWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    px: "24px",
    py: "24px",
  },
  backButton: {
    marginLeft: "24px",
  },
};
