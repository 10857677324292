import { PageFreqAskedQuestions } from "./pages/Content/PageFreqAskedQuestions/PageFreqAskedQuestions";
import { PageGeneralRecommendations } from "./pages/Content/PageGeneralRecommendations/PageGeneralRecommendations";
import { PageGoalSuggestions } from "./pages/Content/PageGoalSuggestions/PageGoalSuggestions";
import { PageSleepAidPosts } from "./pages/PageSleepAidPosts/PageSleepAidPosts";
import { PageUsers } from "./pages/PageUsers/PageUsers";
import { PageEduCategories } from "./pages/PageEduCategories/PageEduCategories";
import { PageEduPosts } from "./pages/PageEduPosts/PageEduPosts";
import { routes } from "./utilities/routes";
import { PageLogin } from "./pages/PageLogin/PageLogin";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./contexts/AuthContext/AuthContext";
import { useAuthGuard } from "./hooks/useAuthGuard";
import { useEffect } from "react";
import { PageTreatments } from "./pages/Treatments/PageTreatments";

export const Router = () => {
  const { isLoggedIn, isLoading } = useAuthContext();
  const authCheck = useAuthGuard();

  useEffect(() => {
    authCheck();
  }, [authCheck]);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Navigate
            replace
            to={routes.userManagement()}
          />
        }
      />
      <Route
        path={routes.login()}
        element={
          isLoggedIn && !isLoading ? (
            <Navigate
              replace
              to={routes.userManagement()}
            />
          ) : (
            <PageLogin />
          )
        }
      />
      <Route
        path={routes.userManagement()}
        element={<PageUsers />}
      />
      <Route
        path={routes.edu().categories()}
        element={<PageEduCategories />}
      />
      <Route
        path={routes.edu().posts()}
        element={<PageEduPosts />}
      />
      <Route
        path={`${routes.edu().init}/*`}
        element={
          <Navigate
            replace
            to={routes.edu().categories()}
          />
        }
      />
      <Route
        path={routes.content().generalRecommendations()}
        element={<PageGeneralRecommendations />}
      />
      <Route
        path={routes.content().goalSuggestions()}
        element={<PageGoalSuggestions />}
      />
      <Route
        path={routes.content().freqAskedQuestions()}
        element={<PageFreqAskedQuestions />}
      />
      <Route
        path={`${routes.content().init}/*`}
        element={
          <Navigate
            replace
            to={routes.content().goalSuggestions()}
          />
        }
      />
      <Route
        path={routes.sleepAidMaterials()}
        element={<PageSleepAidPosts />}
      />
      <Route
        path={routes.treatments()}
        element={<PageTreatments />}
      />
    </Routes>
  );
};
