import { Box, Button, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { sx as sxStyles } from "../../helpers/sx";
import { SxStyles } from "../../theme";
import { Dialog } from "../Dialog/Dialog";
import { AlertDialogProps } from "./AlertDialog.types";

export const AlertDialog = ({
  open,
  title = "",
  text = "",
  handleClose,
  handleSubmit,
}: AlertDialogProps) => {
  return (
    <Dialog
      open={open}
      title={title}
      onClose={handleClose}
      size="sm"
    >
      <Box sx={sx.dialogContainer}>
        {text && (
          <DialogContent sx={sx.alertDialog}>
            <DialogContentText
              sx={sx.textContainer}
              whiteSpace="pre"
            >
              {text}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions sx={sx.dialogButtons}>
          <Button
            variant="text"
            onClick={handleClose}
            sx={sxStyles.cancelButton}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const sx: SxStyles = {
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    boxSizing: "border-box",
    p: "16px",
    pt: 0,
  },
  textContainer: {
    textAlign: "center",
    inlineSize: "100%",
    overflowWrap: "break-word",
  },
  dialogButtons: { justifyContent: "space-between" },
  submitButton: {
    backgroundColor: "navyBlueDark.main",
    color: "common.white",
    "&:hover": { backgroundColor: "darkBlue.main" },
  },
  cancelButton: {
    color: "common.black",
    "&:hover": { textDecoration: "underline" },
    fontWeight: 600,
  },
  alertDialog: {
    minHeight: "40px",
  },
};
