import { routes } from "../../../utilities/routes";
import { Page } from "../MenuWrapper.types";

export const contentPages: Page[] = [
  { label: "Goal Suggestions", path: routes.content().goalSuggestions() },
  { label: "Frequently Asked Questions", path: routes.content().freqAskedQuestions() },
  { label: "General Recommendations", path: routes.content().generalRecommendations() },
];

export const educationPages: Page[] = [
  { label: "Categories", path: routes.edu().categories() },
  { label: "Posts", path: routes.edu().posts() },
];
