import { Box, Button, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { SxStyles } from "../../../theme";
import { ExpandMenuButtonDesktopProps, MenuProps } from "./ExpandMenuButton.types";
import Menu from "@mui/material/Menu";
import { routes } from "../../../utilities/routes";
import { Link as RouterLink } from "react-router-dom";
import { useState, MouseEvent } from "react";
import { contentPages, educationPages } from "./helper";

export const ExpandMenuButtonDesktop = ({ page, pageName }: ExpandMenuButtonDesktopProps) => {
  const { pathname } = useLocation();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const CreateMenu = ({ pages }: MenuProps) => (
    <Menu
      anchorEl={menuAnchorEl}
      open={menuOpen}
      onClose={handleCloseMenu}
    >
      {pages.map((page) => (
        <MenuItem
          key={page.path}
          sx={sx.menuMobileLink}
          onClick={handleCloseMenu}
          component={RouterLink}
          to={page.path}
        >
          {page.label}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Box>
      <Button
        variant="text"
        sx={{
          ...sx.menuDesktopLink,
          borderBottom: pathname.startsWith(pageName) ? "2px solid white" : "none",
        }}
        aria-controls={menuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleOpenMenu}
      >
        {page.label}
        <ExpandMoreIcon
          sx={{
            transform: menuOpen ? "rotate(180deg)" : "none",
            transition: "transform 0.3s ease-in",
          }}
        />
      </Button>
      {menuOpen && pageName === routes.edu().init && <CreateMenu pages={educationPages} />}
      {menuOpen && pageName === routes.content().init && <CreateMenu pages={contentPages} />}
    </Box>
  );
};

const sx: SxStyles = {
  menuDesktopLink: {
    display: "flex",
    textDecoration: "none",
    color: "common.white",
    borderRadius: 0,
  },
};
