import React from "react";
import { Box, IconButton, Menu, MenuItem, SvgIcon } from "@mui/material";
import { MenuActionsProps } from "./MenuActions.types";
import { SxStyles } from "../../theme";

export const MenuActions = ({ menuIcon, options, id }: MenuActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={sx.container}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleClick}
      >
        <SvgIcon component={menuIcon} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((opt) => (
          <MenuItem
            sx={sx.option}
            key={opt.optionText}
            onClick={() => {
              handleClose();
              opt.operation(id);
            }}
          >
            {opt.optionText}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const sx: SxStyles = { option: { fontSize: "14px" } };
