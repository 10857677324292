import { useForm } from "react-hook-form";
import { Box, Paper, TextField, Typography, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { PATTERN_EMAIL } from "../../utilities/patterns";
import "../../styles/index.scss";
import { Form } from "../../components/Form/Form";
import { Logo } from "../../components/Logo/Logo";
import { SxStyles } from "../../theme";
import { useAuthContext } from "../../contexts/AuthContext/AuthContext";
import { LoginData } from "../../contexts/AuthContext/AuthContext.types";
import { ValidationMessage } from "../../utilities/enums";

export function PageLogin() {
  const { login, isLoading, error } = useAuthContext();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<LoginData>();

  const onSubmit = handleSubmit((data) => {
    const loginObj = { email: data.email, password: data.password };
    login(loginObj);
    sx.loadingAnimation = { display: "block" };
    reset();
  });

  return (
    <Box sx={sx.container}>
      <Box>
        <Logo />
      </Box>
      <Paper
        elevation={24}
        sx={sx.paper}
      >
        <Typography
          variant="h5"
          sx={sx.header}
        >
          Log in
        </Typography>
        <Box sx={sx.wrapper}>
          <Form onSubmit={onSubmit}>
            <Box sx={sx.form}>
              <TextField
                {...register("email", {
                  required: true,
                  pattern: PATTERN_EMAIL,
                })}
                sx={sx.input}
                error={!!errors.email}
                helperText={
                  (errors.email?.type === "required" && ValidationMessage.Required) ||
                  (errors.email && ValidationMessage.SpellCheck)
                }
                label="Email"
              />
              <TextField
                sx={sx.input}
                type="password"
                {...register("password", {
                  required: true,
                })}
                error={!!errors.password}
                helperText={errors.password && ValidationMessage.Required}
                label="Password"
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
              <LoadingButton
                type="submit"
                loading={isLoading}
                variant="contained"
                disabled={isLoading}
                sx={sx.button}
              >
                Log In
              </LoadingButton>
            </Box>
          </Form>
        </Box>
      </Paper>
    </Box>
  );
}

const sx: SxStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    alignItems: "center",
  },
  paper: {
    maxWidth: "60vw",
    borderRadius: "15px",
    padding: "30px",
  },
  header: {
    paddingBottom: "20px",
    textAlign: "center",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "50px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
    width: "250px",
  },
  input: {
    width: "100%",
  },
  button: {
    marginTop: "20px",
    width: "100%",
  },

  errorMsg: { color: "error.main" },
};
