import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Dialog } from "../../components/Dialog/Dialog";
import { SxStyles } from "../../theme";
import { TreatmentWeek, WeeksDialogProps } from "./PageTreatments.types";
import { useCallback, useState } from "react";
import { sx as sxStyles } from "../../helpers/sx";
import {
  useAddWeekToTreatmentMutation,
  useDeleteTreatmentWeekMutation,
  useUpdateTreatmentWeekMutation,
} from "../../graphql/client";
import { useHandleError } from "../../hooks/useHandleError";
import { useAlertContext } from "../../contexts/AlertContext/AlertContext";
import { LoadingButton } from "@mui/lab";
import { WeekForm } from "./WeekForm";
import { MenuActions } from "../../components/MenuActions/MenuActions";
import SettingsIcon from "@mui/icons-material/Settings";
import { MenuOption } from "../../components/MenuActions/MenuActions.types";
import { useConfirmationDialog } from "../../contexts/ConfirmationDialog/ConfirmationDialog";
import { useForm } from "react-hook-form";
import { ValidationMessage } from "../../utilities/enums";
import { Form } from "../../components/Form/Form";

export function WeeksDialog({
  isOpen,
  handleClose,
  weeks,
  rowsRefetch,
  treatmentId,
}: WeeksDialogProps) {
  const handleError = useHandleError();
  const { openSnack } = useAlertContext();
  const confirm = useConfirmationDialog();

  const [isWeekFormOpen, setIsWeekFormOpen] = useState(false);
  const [editData, setEditData] = useState<TreatmentWeek | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<TreatmentWeek>();

  const [weekId, setWeekId] = useState("");

  const [isMutationLoading, setIsMutationLoading] = useState(false);

  const { mutate: addWeekToTreatment, isLoading: isLoadingAddWeek } = useAddWeekToTreatmentMutation(
    {
      onError: (e: Error) => handleWeekCreateError(e),
      onSuccess: () => handleWeekCreateSuccess(),
    }
  );

  const { mutate: updateWeek, isLoading: isLoadingUpdateWeek } = useUpdateTreatmentWeekMutation({
    onError: (e: Error) => handleWeekUpdateError(e),
    onSuccess: () => handleWeekUpdateSuccess(),
  });

  const { mutate: deleteWeekMutate } = useDeleteTreatmentWeekMutation({
    onError: (e: Error) => handleWeekDeleteError(e),
    onSuccess: () => handleWeekDeleteSuccess(),
  });

  const handleWeekCreateError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to add week.");
  };

  const handleWeekCreateSuccess = () => {
    setIsMutationLoading(false);
    openSnack({ type: "success", info: "Week added" });
    rowsRefetch();
  };

  const handleWeekUpdateError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to update week.");
  };

  const handleWeekUpdateSuccess = () => {
    setIsMutationLoading(false);
    openSnack({ type: "success", info: "Week updated" });
    setEditData(null);
    rowsRefetch();
  };

  const handleWeekDeleteError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to delete week.");
  };

  const handleWeekDeleteSuccess = () => {
    setIsMutationLoading(false);
    openSnack({ type: "success", info: "Week deleted" });
    rowsRefetch();
  };

  const handleCloseWeekForm = () => {
    setIsWeekFormOpen(false);
    setWeekId("");
  };

  const handleAddNewWeek = useCallback(() => {
    addWeekToTreatment({
      input: { treatmentId: treatmentId, number: weeks.length + 1 },
    });
  }, [addWeekToTreatment, treatmentId, weeks]);

  const createOptions = (week: TreatmentWeek) => {
    const optionsList: MenuOption[] = [
      {
        optionText: "Edit",
        operation: () => handleEditWeek(week),
      },
      {
        optionText: "Delete",
        operation: (id: string) =>
          confirm({
            title: "Delete week",
            text: "Are you sure?",
          }).then(() => {
            deleteWeekMutate({ input: { weekId: week.id } });
          }),
      },
    ];

    return optionsList;
  };

  const handleEditWeek = (week: TreatmentWeek) => {
    setEditData(week);
    setValue("number", week.number);
    setValue("id", week.id);
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsMutationLoading(true);
    updateWeek({ input: { weekId: data.id, number: Number(data.number) } });
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      title={weekId ? "Treatment week" : "Treatment weeks"}
    >
      <DialogContent sx={sx.dialogContent}>
        {weekId ? (
          <WeekForm
            isOpen={isWeekFormOpen}
            handleClose={handleCloseWeekForm}
            rowsRefetch={rowsRefetch}
            treatmentId={treatmentId}
            weekId={weekId}
          />
        ) : (
          <>
            <Stack
              direction="column"
              spacing={1}
              sx={sx.weeksList}
            >
              {weeks
                .sort((a, b) => {
                  if (a?.number && b?.number) {
                    return a?.number - b?.number;
                  } else {
                    return -1;
                  }
                })
                .map((week) => (
                  <Box
                    sx={sx.weekWrapper}
                    key={week?.id}
                  >
                    {editData && editData.id === week?.id ? (
                      <Form onSubmit={onSubmit}>
                        <Box sx={sx.numberInputWrapper}>
                          <TextField
                            sx={sx.numberInput}
                            label="Number"
                            variant="outlined"
                            type="number"
                            placeholder="1"
                            {...register("number", {
                              min: 1,
                              max: 6,
                              required: true,
                            })}
                            error={!!errors.number}
                            helperText={!!errors.number && ValidationMessage.OneToSixNumber}
                          />
                          <DialogActions>
                            <LoadingButton
                              loading={isMutationLoading || isLoadingUpdateWeek}
                              disabled={isMutationLoading}
                              type="submit"
                              variant="contained"
                            >
                              {editData ? "Update" : "Create"}
                            </LoadingButton>
                            <Button
                              onClick={() => {
                                setIsMutationLoading(false);
                                setEditData(null);
                                reset();
                              }}
                              variant="text"
                              sx={sxStyles.cancelButton}
                            >
                              Cancel
                            </Button>
                          </DialogActions>
                        </Box>
                      </Form>
                    ) : (
                      <>
                        <Button
                          sx={sx.week}
                          variant="outlined"
                          onClick={() => {
                            week?.id && setWeekId(week.id);
                          }}
                        >
                          <Typography>Week nr {week?.number}</Typography>
                        </Button>
                        <MenuActions
                          menuIcon={SettingsIcon}
                          options={createOptions({ id: week?.id || "", number: week?.number || 0 })}
                          id={week?.number || 0}
                        />
                      </>
                    )}
                  </Box>
                ))}

              <Box sx={sx.newWeekButtonWrapper}>
                <LoadingButton
                  sx={sx.newWeekButton}
                  type="submit"
                  variant="contained"
                  onClick={() => handleAddNewWeek()}
                  disabled={weeks.length === 6 || isMutationLoading || isLoadingAddWeek}
                  loading={isMutationLoading || isLoadingAddWeek}
                >
                  Add new week
                </LoadingButton>
              </Box>
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

const sx: SxStyles = {
  dialogContent: {
    overflowX: "hidden",
    maxWidth: "100%",
    width: "100%",
  },
  weeksList: {
    alignItems: "center",
  },
  week: {
    color: "common.black",
    maxWidth: "100%",
    width: "300px",
  },
  newWeekButtonWrapper: { paddingTop: "20px" },
  newWeekButton: {
    maxWidth: "100%",
    width: "150px",
  },
  weekWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  numberInputWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
    gap: "5px",
  },
  numberInput: {
    width: "100px",
  },
};
