import { Paper, Box, Button } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Header } from "../../components/Header/Header";
import { Container } from "../../components/Container/Container";
import { formatTreatments, useColumns } from "./helpers";
import {
  ContentSortField,
  Language,
  SortDirection,
  useListTreatmentsWithDetailsQuery,
} from "../../graphql/client";
import { LanguageOptions } from "../../hooks/types";
import { useServerPagination } from "../../hooks/useDataGrid";
import { useHandleError } from "../../hooks/useHandleError";
import { TableFilters } from "../../components/TableFilters/TableFilters";
import { useForm } from "react-hook-form";
import { LanguageSelect } from "../../components/LanguageSelect";
import { sx } from "../../helpers/sx";
import { handleChangeSortDirection } from "../../helpers/sortHandler";
import { TreatmentDataModel, TreatmentWeek } from "./PageTreatments.types";
import { TreatmentDialog } from "./TreatmentModals";
import { WeeksDialog } from "./WeeksModals";

export function PageTreatments() {
  const [pageSize, setPageSize] = useState(8);
  const [isTreatmentDialogOpen, setIsTreatmentDialogOpen] = useState(false);
  const [weekId, setWeekId] = useState<string | null>(null);
  const [isWeeksDialogOpen, setIsWeeksDialogOpen] = useState(false);
  const [editData, setEditData] = useState<TreatmentDataModel | null>(null);
  const [treatmentWeeks, setTreatmentWeeks] = useState<(TreatmentWeek | null)[]>([]);
  const [treatmentId, setTreatmentId] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Desc);
  const handleError = useHandleError();
  const { control, watch } = useForm<LanguageOptions>({
    defaultValues: {
      language: Language.English,
    },
  });

  const [pageCursor, setPageCursor] = useState<string | null>(null);

  const { data, refetch, isFetching } = useListTreatmentsWithDetailsQuery(
    {
      sortDefinition: {
        contentSortField: ContentSortField.Priority,
        sortDirection: sortDirection,
      },
      meta: {
        pageSize: pageSize,
        pageCursor: pageCursor,
      },
    },
    {
      select: formatTreatments,
      keepPreviousData: true,
      onError: (e: Error) => handleError(e),
    }
  );

  const { handlePageChange, currentCursor, page, resetPages } = useServerPagination(isFetching);

  useEffect(() => {
    setPageCursor(currentCursor);
  }, [currentCursor]);

  const handleStartEdit = (treatment: TreatmentDataModel) => {
    setEditData(treatment);
    handleOpenTreatmentDialog();
  };

  const handleOpenWeeks = (id: string, weeks: (TreatmentWeek | null)[]) => {
    setTreatmentWeeks(weeks);
    setTreatmentId(id);
    handleOpenWeeksDialog();
  };

  const columns = useColumns(handleStartEdit, handleOpenWeeks, watch("language"), refetch);

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const handleDisableEdit = () => {
    setEditData(null);
  };

  const handleOpenTreatmentDialog = () => {
    setIsTreatmentDialogOpen(true);
  };

  const handleCloseTreatmentDialog = () => {
    setIsTreatmentDialogOpen(false);
  };

  const handleOpenWeeksDialog = () => {
    setIsWeeksDialogOpen(true);
  };

  const handleCloseWeeksDialog = () => {
    setIsWeeksDialogOpen(false);
  };

  useEffect(() => {
    setTreatmentWeeks(data?.rows.find((row) => row.id === treatmentId)?.weeks || []);
  }, [data, treatmentId]);

  const weeksDialog = useMemo(
    () => (
      <WeeksDialog
        isOpen={isWeeksDialogOpen}
        handleClose={handleCloseWeeksDialog}
        weeks={treatmentWeeks}
        rowsRefetch={refetch}
        treatmentId={treatmentId || ""}
        weekId={weekId}
        handleChangeWeekId={(id) => setWeekId(id)}
      />
    ),
    [isWeeksDialogOpen, refetch, treatmentId, treatmentWeeks, weekId]
  );
  return (
    <Container>
      <Header>Treatments</Header>
      <TreatmentDialog
        isOpen={isTreatmentDialogOpen}
        handleClose={handleCloseTreatmentDialog}
        editData={editData}
        rowsRefetch={refetch}
      />
      {isWeeksDialogOpen && weeksDialog}
      <Paper sx={sx.table}>
        <Box sx={sx.tableButtons}>
          <Button
            sx={sx.submitButton}
            onClick={() => {
              handleOpenTreatmentDialog();
              handleDisableEdit();
            }}
          >
            Create new treatment
          </Button>
        </Box>
        <TableFilters>
          <LanguageSelect control={control} />
        </TableFilters>
        <DataGrid
          sortingMode="server"
          onColumnHeaderClick={(event) =>
            handleChangeSortDirection(event, setSortDirection, resetPages)
          }
          onSortModelChange={resetPages}
          columns={columns}
          rows={data?.rows || []}
          paginationMode="server"
          pageSize={pageSize}
          rowCount={data?.meta.totalCount || 0}
          onPageChange={(newPage) => {
            handlePageChange(newPage, data?.meta.nextPageCursor || "");
          }}
          page={page}
          rowsPerPageOptions={[8, 16, 32]}
          onPageSizeChange={handlePageSizeChange}
          disableSelectionOnClick
          disableColumnMenu
          loading={isFetching}
          disableVirtualization
        />
      </Paper>
    </Container>
  );
}
