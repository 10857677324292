import { Box } from "@mui/material";
import { SxStyles } from "../../theme";
import { MenuWrapperProps } from "./MenuWrapper.types";
import { CreateNavLink } from "./CreateNavLink";

export const MenuDesktopWrapper = ({ pages }: MenuWrapperProps) => {
  return (
    <Box sx={sx.menuDesktopWrapper}>
      {pages.map((page) => (
        <CreateNavLink
          key={page.path}
          page={page}
        />
      ))}
    </Box>
  );
};

const sx: SxStyles = {
  menuDesktopWrapper: {
    flexGrow: 1,
    display: { xs: "none", lg: "flex" },
    alignItems: "center",
    justifyContent: "center",
    direction: "row",
    gap: "70px",
  },
};
