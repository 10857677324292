import { Typography } from "@mui/material";
import { HeaderProps } from "./Header.types";

export function Header({ children }: HeaderProps) {
  return (
    <Typography
      variant="h2"
      component="h2"
    >
      {children}
    </Typography>
  );
}
