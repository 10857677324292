import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Divider,
} from "@mui/material";
import { SxStyles } from "../../theme";
import { Post, PostDialogProps } from "./PageEduPosts.types";
import { useForm } from "react-hook-form";
import { Form } from "../../components/Form/Form";
import { useAlertContext } from "../../contexts/AlertContext/AlertContext";
import { Dialog } from "../../components/Dialog/Dialog";
import { ValidationMessage } from "../../utilities/enums";
import { useEffect } from "react";
import {
  useCreateEducationPostMutation,
  useListCategoriesQuery,
  useUpdateEducationPostMutation,
} from "../../graphql/client";
import { LoadingButton } from "@mui/lab";
import { formatEducationCategories } from "./helpers";
import { sx as sxStyles } from "../../helpers/sx";
import { useHandleError } from "../../hooks/useHandleError";

export const PostDialog = ({ isOpen, handleClose, editData, refetch }: PostDialogProps) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Post>();

  const handleError = useHandleError();

  const { openSnack } = useAlertContext();

  const { data: categories } = useListCategoriesQuery({}, { select: formatEducationCategories });

  const { mutate: createPostMutate, isLoading } = useCreateEducationPostMutation({
    onError: (e: Error) => handleCreatePostError(e),
    onSuccess: () => handleCreatePostSuccess(),
  });

  const { mutate: updateMutate } = useUpdateEducationPostMutation({
    onError: (e: Error) => handleUpdateError(e),
    onSuccess: () => handleUpdateSuccess(),
  });

  const handleCreatePostSuccess = () => {
    reset();
    handleCloseDialog();
    refetch();
    openSnack({ type: "success", info: "Post created" });
  };

  const handleCreatePostError = (e: Error) => {
    handleError(e, "Failed to create post.");
  };

  const handleUpdateSuccess = () => {
    reset();
    handleCloseDialog();
    refetch();
    openSnack({ type: "success", info: "Post updated" });
  };

  const handleUpdateError = (e: Error) => {
    handleError(e, "Failed to update post.");
  };

  const onSubmit = handleSubmit((data) => {
    const { basicEducationCategoryId, priority, ...postObj } = data;

    editData
      ? updateMutate({ input: { id: editData?.id, priority: Number(priority), ...postObj } })
      : createPostMutate({
          input: { basicEducationCategoryId, priority: Number(priority), ...postObj },
        });
  });

  const handleCloseDialog = () => {
    handleClose();
  };

  useEffect(() => {
    reset();
    if (editData) {
      setValue("englishTitle", editData.englishTitle);
      setValue("englishContent", editData.englishContent);
      setValue("icelandicTitle", editData.icelandicTitle);
      setValue("icelandicContent", editData.icelandicContent);
      setValue("priority", editData.priority);
    }
  }, [setValue, editData, reset]);

  const CategorySelect = () => (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Category</InputLabel>
      <Select
        variant="standard"
        {...register("basicEducationCategoryId", {
          required: !editData,
        })}
        label="Category"
        error={!!errors.basicEducationCategoryId}
        defaultValue=""
      >
        {categories?.map((category) => (
          <MenuItem
            key={category.id}
            value={category.id}
          >
            {category.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>
        {!!errors.basicEducationCategoryId && ValidationMessage.Required}
      </FormHelperText>
    </FormControl>
  );

  const PushNotificationInfo = () => (
    <Typography sx={sx.info}>(Push notification will be sent to users)</Typography>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      title={editData ? "Post update" : "Create new post"}
    >
      {!editData && <PushNotificationInfo />}
      <DialogContent sx={sx.dialogContent}>
        <Form onSubmit={onSubmit}>
          {!editData && (
            <Box sx={sx.selectInput}>
              <CategorySelect />
            </Box>
          )}
          {!editData && <Divider />}
          <Box sx={sx.form}>
            <Box sx={sx.inputs}>
              <Typography sx={sx.dialogLabel}>English</Typography>
              <TextField
                label="Title"
                {...register("englishTitle", {
                  required: true,
                })}
                error={!!errors.englishTitle}
                helperText={!!errors.englishTitle && ValidationMessage.Required}
              />
              <TextField
                label="Content"
                variant="outlined"
                placeholder="Content"
                multiline
                rows={7}
                {...register("englishContent", {
                  required: true,
                })}
                error={!!errors.englishContent}
                helperText={!!errors.englishContent && ValidationMessage.Required}
              />
            </Box>
            <Divider />
            <Box sx={sx.inputs}>
              <Typography sx={sx.dialogLabel}>Icelandic</Typography>
              <TextField
                label="Title"
                {...register("icelandicTitle", {
                  required: true,
                })}
                error={!!errors.icelandicTitle}
                helperText={!!errors.icelandicTitle && ValidationMessage.Required}
              />
              <TextField
                label="Content"
                variant="outlined"
                placeholder="Content"
                multiline
                rows={7}
                {...register("icelandicContent", {
                  required: true,
                })}
                error={!!errors.icelandicContent}
                helperText={!!errors.icelandicTitle && ValidationMessage.Required}
              />
            </Box>
          </Box>
          <Box sx={sx.inputContainer}>
            <TextField
              sx={sxStyles.priorityInput}
              label="Priority"
              variant="outlined"
              type="number"
              placeholder="0"
              {...register("priority", {
                min: 0,
              })}
              error={!!errors.priority}
              helperText={!!errors.priority && ValidationMessage.NonNegativeNumber}
            />
          </Box>
          <DialogActions>
            <Button
              variant="text"
              sx={sxStyles.cancelButton}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoading}
              disabled={isLoading}
              type="submit"
              variant="contained"
            >
              {editData ? "Update" : "Create"}
            </LoadingButton>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const sx: SxStyles = {
  dialogContent: {
    overflowX: "hidden",
    maxWidth: "100%",
  },
  inputs: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "400px",
    maxWidth: "100%",
  },
  form: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: "30px",
    p: "15px",
  },
  dialogLabel: { fontWeight: 600 },
  errorMsg: { color: "error.main", fontSize: "0.75rem" },
  selectInput: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "250px",
    width: "100%",
    mx: "auto",
    mt: "10px",
    mb: "30px",
  },
  info: { opacity: "0.5", mx: "auto" },
  inputContainer: { paddingLeft: "15px", marginTop: "-15px" },
};
