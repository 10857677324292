import { GridColDef } from "@mui/x-data-grid";

export const columnPriority: GridColDef = {
  field: "priority",
  headerName: "Priority",
  align: "center",
  maxWidth: 100,
  filterable: false,
  renderCell: (params) => {
    return params.row.priority;
  },
};
