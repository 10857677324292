import { Paper, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Header } from "../../components/Header/Header";
import { Container } from "../../components/Container/Container";
import { CategoryDialog, ImageModal } from "./CategoryModals";
import { formatEducationCategories, useColumns } from "./helpers";
import {
  Language,
  useCategoriesQuery,
  ContentSortField,
  SortDirection,
} from "../../graphql/client";
import { CategoryDataModel } from "./PageEduCategories.types";
import { LanguageOptions } from "../../hooks/types";
import { useServerPagination } from "../../hooks/useDataGrid";
import { useHandleError } from "../../hooks/useHandleError";
import { TableFilters } from "../../components/TableFilters/TableFilters";
import { useForm } from "react-hook-form";
import { LanguageSelect } from "../../components/LanguageSelect";
import { sx } from "../../helpers/sx";
import { handleChangeSortDirection } from "../../helpers/sortHandler";

export function PageEduCategories() {
  const [pageSize, setPageSize] = useState(8);
  const [isCategoryDialogOpen, setIsCategoryDialogOpen] = useState(false);
  const [openedImage, setOpenedImage] = useState("");
  const [editData, setEditData] = useState<CategoryDataModel | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Desc);
  const handleError = useHandleError();
  const { control, watch } = useForm<LanguageOptions>({
    defaultValues: {
      language: Language.English,
    },
  });

  const [pageCursor, setPageCursor] = useState<string | null>(null);

  const { data, refetch, isFetching } = useCategoriesQuery(
    {
      sortDefinition: {
        contentSortField: ContentSortField.Priority,
        sortDirection: sortDirection,
      },
      meta: {
        pageSize: pageSize,
        pageCursor: pageCursor,
      },
    },
    {
      select: formatEducationCategories,
      keepPreviousData: true,
      onError: (e: Error) => handleError(e),
    }
  );

  const { handlePageChange, currentCursor, page, resetPages } = useServerPagination(isFetching);

  useEffect(() => {
    setPageCursor(currentCursor);
  }, [currentCursor]);

  const handleStartEdit = (category: CategoryDataModel) => {
    setEditData(category);
    handleOpenCategoryDialog();
  };

  const handleOpenImage = (url: string) => {
    setOpenedImage(url);
  };

  const columns = useColumns(handleStartEdit, handleOpenImage, watch("language"), refetch);

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const handleDisableEdit = () => {
    setEditData(null);
  };

  const handleOpenCategoryDialog = () => {
    setIsCategoryDialogOpen(true);
  };

  const handleCloseCategoryDialog = () => {
    setIsCategoryDialogOpen(false);
  };

  const handleCloseImage = () => {
    setOpenedImage("");
  };

  return (
    <Container>
      <Header>Education Categories</Header>
      <CategoryDialog
        isOpen={isCategoryDialogOpen}
        handleClose={handleCloseCategoryDialog}
        editData={editData}
        rowsRefetch={refetch}
      />
      <ImageModal
        openedImage={openedImage}
        handleCloseImage={handleCloseImage}
      />
      <Paper sx={sx.table}>
        <Box sx={sx.tableButtons}>
          <Button
            sx={sx.submitButton}
            onClick={() => {
              handleOpenCategoryDialog();
              handleDisableEdit();
            }}
          >
            Create new category
          </Button>
        </Box>
        <TableFilters>
          <LanguageSelect control={control} />
        </TableFilters>
        <DataGrid
          sortingMode="server"
          onColumnHeaderClick={(event) =>
            handleChangeSortDirection(event, setSortDirection, resetPages)
          }
          onSortModelChange={resetPages}
          columns={columns}
          rows={data?.rows || []}
          paginationMode="server"
          pageSize={pageSize}
          rowCount={data?.meta.totalCount || 0}
          onPageChange={(newPage) => {
            handlePageChange(newPage, data?.meta.nextPageCursor || "");
          }}
          page={page}
          rowsPerPageOptions={[8, 16, 32]}
          onPageSizeChange={handlePageSizeChange}
          disableSelectionOnClick
          disableColumnMenu
          loading={isFetching}
          disableVirtualization
        />
      </Paper>
    </Container>
  );
}
