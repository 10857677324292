import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";
import { FetchResult } from "../components/ContentFormDialog/ContentFormDialog.types";
import { useAlertContext } from "../contexts/AlertContext/AlertContext";
import { useHandleError } from "./useHandleError";

export const useHandleMutationResponse = () => {
  const handleError = useHandleError();
  const { openSnack } = useAlertContext();

  const handleMutationError = (error: Error, setFetchResult?: (result: FetchResult) => void) => {
    if (setFetchResult) {
      setFetchResult(FetchResult.error);
    }
    handleError(error);
  };

  const handleMutationSuccess = (
    refetch: <TPageData>(
      options?: RefetchOptions & RefetchQueryFilters<TPageData>
    ) => Promise<QueryObserverResult>,
    info: string,
    setFetchResult?: (result: FetchResult) => void
  ) => {
    if (setFetchResult) {
      setFetchResult(FetchResult.success);
    }
    refetch();
    openSnack({ type: "success", info });
  };

  return { handleMutationSuccess, handleMutationError };
};
