import { Button, DialogContent, Typography } from "@mui/material";
import { useState } from "react";
import { SxStyles } from "../../theme";
import { Dialog } from "../Dialog/Dialog";
import { TextContentButtonProps } from "./TextContentButton.types";

export const TextContentButton = ({ content }: TextContentButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        onClick={() => {
          handleOpen();
        }}
      >
        <Typography
          sx={sx.contentButton}
          noWrap
        >
          {content}
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <Typography whiteSpace="pre-wrap">{content}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

const sx: SxStyles = {
  contentButton: {
    color: "common.black",
    fontsize: "0.875rem",
  },
};
