import { Box, Button, DialogActions, FormHelperText, TextField, Typography } from "@mui/material";
import { SxStyles } from "../../theme";
import {
  TreatmentWeekVideo,
  TreatmentWeekVideoDataModel,
  TreatmentWeekVideoFormProps,
} from "./PageTreatments.types";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ValidationMessage } from "../../utilities/enums";
import { Form } from "../../components/Form/Form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  GetTreatmentVideoThumbnailUploadUrlQuery,
  GetTreatmentVideoUploadUrlQuery,
  Language,
  useAddVideoToTreatmentWeekMutation,
  useGetTreatmentVideoThumbnailUploadUrlQuery,
  useGetTreatmentVideoUploadUrlQuery,
  useUpdateTreatmentVideoMutation,
} from "../../graphql/client";
import { LoadingButton } from "@mui/lab";
import { sx as sxStyles } from "../../helpers/sx";
import { formatVideoDuration } from "./helpers";
import { useHandleError } from "../../hooks/useHandleError";
import { useAlertContext } from "../../contexts/AlertContext/AlertContext";
import { MediaType, uploadMedia } from "../../helpers/mediaUploader";

export function TreatmentWeekVideoForm({
  handleClose,
  weekId: fetchedWeekId,
  editData,
  treatmentWeekData,
  refetch,
  handleChangeVideos,
}: TreatmentWeekVideoFormProps) {
  const handleError = useHandleError();
  const { openSnack } = useAlertContext();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TreatmentWeekVideo>();

  const [isMutationLoading, setIsMutationLoading] = useState(false);

  const { mutate: addVideoToTreatmentWeek, isLoading: isLoadingCreate } =
    useAddVideoToTreatmentWeekMutation({
      onError: (e: Error) => handleVideoAddError(e),
      onSuccess: () => handleVideoAddSuccess(),
    });

  const { mutate: updateTreatmentVideo, isLoading: isLoadingUpdate } =
    useUpdateTreatmentVideoMutation({
      onError: (e: Error) => handleVideoUpdateError(e),
      onSuccess: () => handleVideoUpdateSuccess(),
    });

  const { data: videoThumbnailUploadUrlData, refetch: refetchVideoThumbnailUploadUrl } =
    useGetTreatmentVideoThumbnailUploadUrlQuery<GetTreatmentVideoThumbnailUploadUrlQuery>({});

  const { data: videoUploadUrlData, refetch: refetchVideoUploadUrl } =
    useGetTreatmentVideoUploadUrlQuery<GetTreatmentVideoUploadUrlQuery>({});

  const handleVideoUpdateError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to update video.");
  };

  const handleVideoUpdateSuccess = () => {
    setIsMutationLoading(false);
    openSnack({ type: "success", info: "Video updated" });
    getVideos();
    handleClose();
  };

  const handleVideoAddError = (e: Error) => {
    setIsMutationLoading(false);
    handleError(e, "Failed to add video.");
  };

  const handleVideoAddSuccess = () => {
    setIsMutationLoading(false);
    openSnack({ type: "success", info: "Video added" });
    getVideos();
    handleClose();
  };

  const onSubmit = handleSubmit(async (form) => {
    setIsMutationLoading(true);

    if (videoThumbnailUploadUrlData && videoUploadUrlData) {
      const { priority, weekId, ...obj } = form;
      const data = { priority: Number(priority), weekId: fetchedWeekId, ...obj };
      const englishVideoThumbnail = data.englishVideoThumbnail?.[0];
      const icelandicVideoThumbnail = data.icelandicVideoThumbnail?.[0];
      const englishVideo = data.englishVideo?.[0];
      const icelandicVideo = data.icelandicVideo?.[0];
      let thumbnailCreateUrlEnglish = "";
      let thumbnailCreateUrlIcelandic = "";
      let videoCreateUrlEnglish = "";
      let videoCreateUrlIcelandic = "";
      const firstThumbnailUploadUrl =
        videoThumbnailUploadUrlData?.content.getTreatmentVideoThumbnailUploadUrl.url;
      const firstVideoUploadUrl = videoUploadUrlData?.content.getTreatmentVideoUploadUrl.url;
      let urls;
      if (englishVideoThumbnail.size > 0) {
        if (firstThumbnailUploadUrl) {
          urls = await uploadMedia<GetTreatmentVideoThumbnailUploadUrlQuery>(
            MediaType.image,
            englishVideoThumbnail,
            firstThumbnailUploadUrl,
            refetchVideoThumbnailUploadUrl
          );
        }
        thumbnailCreateUrlEnglish = urls?.mediaUrl || "";
      }

      if (icelandicVideoThumbnail.size > 0) {
        if (urls?.uploadUrl?.content?.getTreatmentVideoThumbnailUploadUrl?.url) {
          const newUploadUrl = urls?.uploadUrl.content.getTreatmentVideoThumbnailUploadUrl?.url;

          urls = await uploadMedia<GetTreatmentVideoThumbnailUploadUrlQuery>(
            MediaType.image,
            icelandicVideoThumbnail,
            newUploadUrl,
            refetchVideoThumbnailUploadUrl
          );
        } else if (firstThumbnailUploadUrl) {
          urls = await uploadMedia<GetTreatmentVideoThumbnailUploadUrlQuery>(
            MediaType.image,
            icelandicVideoThumbnail,
            firstThumbnailUploadUrl,
            refetchVideoThumbnailUploadUrl
          );
        }
        thumbnailCreateUrlIcelandic = urls?.mediaUrl || "";
      }

      if (englishVideo.size > 0) {
        if (firstVideoUploadUrl) {
          urls = await uploadMedia<GetTreatmentVideoUploadUrlQuery>(
            MediaType.video,
            englishVideo,
            firstVideoUploadUrl,
            refetchVideoUploadUrl
          );
        }
        videoCreateUrlEnglish = urls?.mediaUrl || "";
      }

      if (icelandicVideo.size > 0) {
        if (urls?.uploadUrl as GetTreatmentVideoUploadUrlQuery) {
          // for some reason it has "getTreatmentVideoUploadUrl" instead of "getTreatmentVideoThumbnailUploadUrl" like above
          const newUploadUrl = (urls?.uploadUrl as any).content.getTreatmentVideoUploadUrl?.url;

          urls = await uploadMedia<GetTreatmentVideoUploadUrlQuery>(
            MediaType.video,
            icelandicVideo,
            newUploadUrl,
            refetchVideoUploadUrl
          );
        } else if (firstVideoUploadUrl) {
          urls = await uploadMedia<GetTreatmentVideoUploadUrlQuery>(
            MediaType.video,
            icelandicVideo,
            firstVideoUploadUrl,
            refetchVideoUploadUrl
          );
        }
        videoCreateUrlIcelandic = urls?.mediaUrl || "";
      }

      let formatedVidDurationEng = "";
      let formatedVidDurationIce = "";
      const videoEng = document.createElement("video");
      const videoIce = document.createElement("video");

      videoEng.src = videoCreateUrlEnglish;
      videoIce.src = videoCreateUrlIcelandic;

      if (editData) {
        const {
          englishVideoThumbnail,
          englishVideo,
          icelandicVideoThumbnail,
          icelandicVideo,
          englishVideoDuration,
          icelandicVideoDuration,
          priority,
          weekId,
          ...rest
        } = data;
        const mutate = () =>
          updateTreatmentVideo({
            input: {
              videoId: editData.videoId,
              englishVideoThumbnailUrl:
                thumbnailCreateUrlEnglish || editData.englishVideoThumbnailUrl,
              englishVideoUrl: videoCreateUrlEnglish || editData.englishVideoUrl,
              englishVideoDuration: formatedVidDurationEng || editData.englishVideoDuration,
              icelandicVideoThumbnailUrl:
                thumbnailCreateUrlIcelandic || editData.icelandicVideoThumbnailUrl,
              icelandicVideoUrl: videoCreateUrlIcelandic || editData.icelandicVideoUrl,
              icelandicVideoDuration: formatedVidDurationIce || icelandicVideoDuration,
              priority: Number(priority),
              ...rest,
            },
          });

        if (videoCreateUrlEnglish) {
          formatedVidDurationEng = await formatVideoDuration(videoEng);
          if (!videoCreateUrlIcelandic) {
            mutate();
          }
        }
        if (videoCreateUrlIcelandic) {
          formatedVidDurationIce = await formatVideoDuration(videoIce);
          mutate();
        }
        if (!videoCreateUrlEnglish && !videoCreateUrlIcelandic) {
          mutate();
        }
      } else {
        if (
          thumbnailCreateUrlEnglish &&
          thumbnailCreateUrlIcelandic &&
          videoCreateUrlEnglish &&
          videoCreateUrlIcelandic
        ) {
          const {
            englishVideoThumbnail,
            englishVideo,
            icelandicVideoThumbnail,
            icelandicVideo,
            icelandicVideoDuration,
            englishVideoDuration,
            priority,
            ...rest
          } = data;

          const [formatedVidDurationEng, formatedVidDurationIce] = await Promise.all([
            formatVideoDuration(videoEng),
            formatVideoDuration(videoIce),
          ]);

          addVideoToTreatmentWeek({
            input: {
              englishVideoThumbnailUrl: thumbnailCreateUrlEnglish,
              englishVideoUrl: videoCreateUrlEnglish,
              icelandicVideoThumbnailUrl: thumbnailCreateUrlIcelandic,
              icelandicVideoUrl: videoCreateUrlIcelandic,
              englishVideoDuration: formatedVidDurationEng,
              icelandicVideoDuration: formatedVidDurationIce,
              priority: Number(priority),
              ...rest,
            },
          });
        }
      }
    }
  });

  useEffect(() => {
    if (editData) {
      setValue("englishTitle", editData.englishTitle);
      setValue("englishContent", editData.englishContent);
      setValue("englishVideoThumbnail", [new File([""], "current video thumbnail")]);
      setValue("englishVideo", [new File([], "current video")]);
      setValue("englishVideoDuration", editData.englishVideoDuration);
      setValue("icelandicTitle", editData.icelandicTitle);
      setValue("icelandicContent", editData.icelandicContent);
      setValue("icelandicVideoThumbnail", [new File([""], "current video thumbnail")]);
      setValue("icelandicVideo", [new File([], "current video")]);
      setValue("icelandicVideoDuration", editData.icelandicVideoDuration);
      setValue("priority", editData.priority);
    }
  }, [editData, setValue]);

  const getVideos = useCallback(() => {
    if (fetchedWeekId) {
      refetch();
      const fetchedVideos = treatmentWeekData?.content?.getTreatmentWeekWithDetails?.videos;

      if (fetchedVideos && fetchedVideos?.length > 0) {
        const videosArray: TreatmentWeekVideoDataModel[] =
          fetchedVideos.map((video) => {
            const enIndex = video?.details[0]?.language === Language.English ? 0 : 1;
            const iceIndex = video?.details[0]?.language === Language.Icelandic ? 0 : 1;

            const obj: TreatmentWeekVideoDataModel = {
              videoId: video?.id || "",
              priority: Number(video?.priority) || 0,
              weekId: fetchedWeekId,
              englishTitle: video?.details[enIndex]?.title || "",
              englishContent: video?.details[enIndex]?.content || "",
              englishVideoUrl: video?.details[enIndex]?.videoUrl || "",
              englishVideoThumbnailUrl: video?.details[enIndex]?.videoThumbnailUrl || "",
              englishVideoDuration: video?.details[enIndex]?.videoDuration || "",
              icelandicTitle: video?.details[iceIndex]?.title || "",
              icelandicContent: video?.details[iceIndex]?.content || "",
              icelandicVideoUrl: video?.details[iceIndex]?.videoUrl || "",
              icelandicVideoThumbnailUrl: video?.details[iceIndex]?.videoThumbnailUrl || "",
              icelandicVideoDuration: video?.details[iceIndex]?.videoDuration || "",
            };

            return obj;
          }) || [];

        handleChangeVideos(videosArray);
      } else {
        handleChangeVideos([]);
      }
    }
  }, [
    handleChangeVideos,
    refetch,
    treatmentWeekData?.content?.getTreatmentWeekWithDetails?.videos,
    fetchedWeekId,
  ]);

  return (
    <Form onSubmit={onSubmit}>
      <Box sx={sx.formContainer}>
        <Box sx={sx.inputContainer}>
          <Typography sx={sx.dialogLabel}>English</Typography>
          <TextField
            sx={sx.title}
            label="Title"
            variant="standard"
            placeholder="Title"
            fullWidth
            {...register("englishTitle", {
              required: true,
            })}
            error={!!errors.englishTitle}
            helperText={!!errors.englishTitle && ValidationMessage.Required}
          />
          <TextField
            label="Content"
            variant="outlined"
            placeholder="Content"
            multiline
            rows={7}
            {...register("englishContent", {
              required: true,
            })}
            error={!!errors.englishContent}
            helperText={!!errors.englishContent && ValidationMessage.Required}
          />
          <Box sx={sx.multimediaInputContainer}>
            <Button
              variant="contained"
              component="label"
              sx={sx.uploadButton}
            >
              <FileUploadIcon />
              <Typography sx={sx.multimediaLabel}>Upload Video</Typography>
              <input
                id="enVideo"
                type="file"
                accept="video/*"
                alt="englishVideo"
                hidden
                {...register("englishVideo", {
                  required: !editData,
                })}
              />
            </Button>
            {!!errors.englishVideo && (
              <FormHelperText error>{ValidationMessage.Required}</FormHelperText>
            )}
            {watch("englishVideo") && (
              <Typography sx={sx.multimediaLabel}>{watch("englishVideo")?.[0]?.name}</Typography>
            )}
          </Box>
          <Box sx={sx.multimediaInputContainer}>
            <Button
              variant="contained"
              component="label"
              sx={sx.uploadButton}
            >
              <FileUploadIcon />
              <Typography sx={sx.multimediaLabel}>Upload Video Thumbnail</Typography>
              <input
                type="file"
                accept="image/*"
                alt="englishVideoThumbnail"
                hidden
                {...register("englishVideoThumbnail", {
                  required: !editData,
                })}
              />
            </Button>
            {!!errors.englishVideoThumbnail && (
              <FormHelperText error>{ValidationMessage.Required}</FormHelperText>
            )}
            {watch("englishVideoThumbnail") && (
              <Typography sx={sx.multimediaLabel}>
                {watch("englishVideoThumbnail")?.[0]?.name}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={sx.inputContainer}>
          <Typography sx={sx.dialogLabel}>Icelandic</Typography>
          <TextField
            sx={sx.title}
            label="Title"
            variant="standard"
            placeholder="Title"
            fullWidth
            {...register("icelandicTitle", {
              required: true,
            })}
            error={!!errors.icelandicTitle}
            helperText={!!errors.icelandicTitle && ValidationMessage.Required}
          />
          <TextField
            label="Content"
            variant="outlined"
            placeholder="Content"
            multiline
            rows={7}
            {...register("icelandicContent", {
              required: true,
            })}
            error={!!errors.icelandicContent}
            helperText={!!errors.icelandicContent && ValidationMessage.Required}
          />
          <Box sx={sx.multimediaInputContainer}>
            <Button
              variant="contained"
              component="label"
              sx={sx.uploadButton}
            >
              <FileUploadIcon />
              <Typography sx={sx.multimediaLabel}>Upload Video</Typography>
              <input
                type="file"
                accept="video/*"
                alt="icelandicVideo"
                hidden
                {...register("icelandicVideo", {
                  required: !editData,
                })}
              />
            </Button>
            {!!errors.icelandicVideo && (
              <FormHelperText error>{ValidationMessage.Required}</FormHelperText>
            )}
            {watch("icelandicVideo")?.[0]?.name && (
              <Typography sx={sx.multimediaLabel}>{watch("icelandicVideo")?.[0]?.name}</Typography>
            )}
          </Box>
          <Box sx={sx.multimediaInputContainer}>
            <Button
              variant="contained"
              component="label"
              sx={sx.uploadButton}
            >
              <FileUploadIcon />
              <Typography sx={sx.multimediaLabel}>Upload Video Thumbnail</Typography>
              <input
                type="file"
                accept="image/*"
                alt="icelandicVideoThumbnail"
                hidden
                {...register("icelandicVideoThumbnail", {
                  required: !editData,
                })}
              />
            </Button>
            {!!errors.icelandicVideoThumbnail && (
              <FormHelperText error>{ValidationMessage.Required}</FormHelperText>
            )}
            {watch("icelandicVideoThumbnail") && (
              <Typography sx={sx.multimediaLabel}>
                {watch("icelandicVideoThumbnail")?.[0]?.name}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={sx.priorityWrapper}>
        <TextField
          sx={sxStyles.priorityInput}
          label="Priority"
          variant="outlined"
          type="number"
          placeholder="0"
          {...register("priority", {
            min: 0,
          })}
          error={!!errors.priority}
          helperText={!!errors.priority && ValidationMessage.NonNegativeNumber}
        />
      </Box>
      <DialogActions>
        <Button
          onClick={() => {
            setIsMutationLoading(false);
            handleClose();
          }}
          variant="text"
          sx={sxStyles.cancelButton}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoadingCreate || isLoadingUpdate || isMutationLoading}
          disabled={isLoadingCreate || isLoadingUpdate || isMutationLoading}
          type="submit"
          variant="contained"
        >
          {editData ? "Update" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Form>
  );
}

const sx: SxStyles = {
  dialogLabel: { fontWeight: 600 },
  formContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: "60px",
    py: "20px",
    px: "24px",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "100%",
    width: "400px",
    mx: "auto",
  },
  multimediaLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
    maxWidth: "180px",
    maxHeight: "20px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  multimediaInputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "bottom",
    justifyContent: "end",
    gap: "7px",
  },
  uploadButton: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    width: "100%",
  },
  priorityWrapper: {
    pl: "24px",
  },
};
