import { Box, ClickAwayListener, MenuItem, MenuList } from "@mui/material";
import { SxStyles } from "../../../theme";
import { ExpandMenuButtonMobileProps, MenuProps } from "./ExpandMenuButton.types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../../utilities/routes";
import { contentPages, educationPages } from "./helper";
import { useState } from "react";

export const ExpandMenuButtonMobile = ({
  page,
  pageName,
  closeMobileMenu,
}: ExpandMenuButtonMobileProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const CreateMenu = ({ pages }: MenuProps) => (
    <MenuList sx={sx.menu}>
      {pages.map((page) => (
        <MenuItem
          key={page.path}
          sx={sx.menuMobileLink}
          onClick={closeMobileMenu}
          component={RouterLink}
          to={page.path}
        >
          {page.label}
        </MenuItem>
      ))}
    </MenuList>
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <MenuItem
          sx={sx.menuMobileLink}
          onClick={handleToggle}
        >
          {page.label}
          <ExpandMoreIcon
            sx={{
              transform: open ? "rotate(180deg)" : "none",
              transition: "transform 0.3s ease-in",
            }}
          />
        </MenuItem>
        {open && pageName === routes.edu().init && <CreateMenu pages={educationPages} />}
        {open && pageName === routes.content().init && <CreateMenu pages={contentPages} />}
      </Box>
    </ClickAwayListener>
  );
};

const sx: SxStyles = {
  menu: { backgroundColor: "secondary.light" },
  menuMobileLink: {
    textDecoration: "none",
    color: "common.black",
  },
};
